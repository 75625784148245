/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '16-lkd': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M16 14.545h-3.536V9.432c0-1.338-.553-2.252-1.77-2.252-.93 0-1.448.617-1.688 1.21-.09.214-.077.511-.077.808v5.347H5.426s.045-9.057 0-9.88H8.93v1.55c.207-.678 1.327-1.646 3.113-1.646C14.26 4.57 16 5.991 16 9.051v5.494zM1.883 3.428h-.022C.73 3.428 0 2.673 0 1.716 0 .74.754 0 1.905 0c1.15 0 1.858.738 1.88 1.713 0 .957-.73 1.715-1.902 1.715zM.403 4.664h3.12v9.881H.402v-9.88z" _fill="#000" fill-rule="evenodd"/>'
  }
})
