/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '16-fb': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8.546 16H.883A.883.883 0 010 15.117V.883C0 .395.395 0 .883 0h14.234c.488 0 .883.395.883.883v14.234a.883.883 0 01-.883.883H11.04V9.804h2.08l.31-2.415h-2.39V5.848c0-.7.194-1.176 1.196-1.176h1.28v-2.16c-.222-.03-.981-.095-1.864-.095-1.844 0-3.106 1.125-3.106 3.191V7.39H6.461v2.415h2.085V16z" _fill="#000" fill-rule="evenodd"/>'
  }
})
