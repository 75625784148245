/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '16-behance': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M10.224 4.669h4.237V3.455h-4.237v1.214zm1.004 2.648c-.27.275-.44.647-.51 1.117h3.295c-.035-.501-.206-.88-.509-1.14-.306-.26-.684-.389-1.135-.389-.49 0-.871.138-1.141.412zm2.86-1.63c.51.225.93.582 1.262 1.068.302.429.496.926.584 1.491.052.33.073.81.064 1.432h-5.327c.032.723.284 1.231.766 1.522.291.18.642.271 1.054.271.434 0 .788-.11 1.06-.33.15-.12.282-.285.394-.496h1.953c-.052.426-.29.862-.709 1.303-.655.701-1.572 1.052-2.75 1.052-.974 0-1.833-.295-2.575-.887-.746-.591-1.117-1.555-1.117-2.887 0-1.25.336-2.21 1.007-2.877.672-.665 1.542-1 2.614-1 .637 0 1.21.113 1.72.339zM1.97 8.46v2.601h2.4c.43 0 .764-.057 1.002-.171.434-.214.65-.619.65-1.218 0-.509-.208-.857-.629-1.047-.235-.106-.565-.161-.989-.165H1.971zm0-3.766v2.152h2.404c.428 0 .777-.08 1.046-.242.268-.162.402-.448.402-.856 0-.456-.177-.755-.53-.902-.307-.101-.697-.152-1.17-.152H1.971zm5.37-.655c.302.415.453.91.453 1.49 0 .594-.151 1.074-.457 1.436-.171.202-.423.388-.755.555.505.182.885.468 1.143.861.256.392.385.869.385 1.43 0 .577-.147 1.096-.441 1.555a2.71 2.71 0 01-1.817 1.257c-.43.088-.894.131-1.397.131H0V3h4.778c1.206.018 2.06.364 2.563 1.04z" _fill="#000" fill-rule="evenodd"/>'
  }
})
