<template>
  <div class="p-nightcall">
    <HeadProject
      titleFir="Night"
      titleSec="call"
      :backline="false"
      type="Project photographique"
      client="Personnel"
      role="Photographe"
      date="Novembre 2021"
    />
    <section class="p-nightcall__intro pt200 pb160" data-aos="fade-up">
      <div class="p-nightcall__intro-cover">
        <img
          src="@/assets/nightcall/underthebridge.jpg"
          srcset="@/assets/nightcall/2x/underthebridge.jpg 2x"
          alt="Sous un pont en brume"
          data-aos="fade-in"
          data-aos-delay="300"
        />
      </div>
      <div class="wrap">
        <h2 class="p-nightcall__intro-nightcall1" data-aos="fade-up"  data-aos-delay="300">
        11 novembre.</h2>
        <h3 class="p-nightcall__intro-nightcall2 -time mt48 mb8" data-aos="fade-up"  data-aos-delay="300">
        2h59.</h3>
        <p class="p-nightcall__intro-nightcall3 -quote" data-aos="fade-up"  data-aos-delay="300">
        Les nuits étaient particulièrement fraîches depuis quelques jours. Un brouillard revenait à chaque fois, et juste avant de me coucher, je plongeais mes yeux dans ce vide infini aux couleurs pastel, dégradées.</p>
        <div class="p-nightcall__intro-nightcall4 mt48" data-aos="fade-up"  data-aos-delay="300">
          <p class="-body">Chaque soir, je m’endormais en me disant que certains lieux seraient visuellement somptueux, sous cet habillage, aux multiples lumières diffuses.</p><br />
          <p class="-body">Ce matin du 11 novembre, tandis que j’allais me coucher, je jetais un œil par réflexe à la fenêtre et un sentiment de frustration m’envahit subitement.</p><br />
          <p class="-body">Le temps était optimal, et bien que la température fut un peu basse (1°C), l’occasion semblait parfaite : ce jour étant férié, une nuit blanche pouvait largement se tenter.</p>
        </div>
        <div class="p-nightcall__intro-nightcall5 -grid mt120" data-aos="fade-up">
          <h3 class="p-nightcall__intro-nightcall5-title mb40">Aparté sérieux ☝️</h3>
          <div class="p-nightcall__intro-nightcall5-left">
            <p class="-body">J’étais dans une période pas très réjouissante de ma vie. Je me battais chaque jour contre une dépression chronique, et le sentiment constant de ne plus rien avoir envie de faire. (dans le meilleur des cas)</p><br />
            <p class="-body">Quelque part si, je le souhaitais au fond de moi, mais que feriez-vous si plus rien n’avait réellement de valeur ou de sens à vos yeux ?</p><br />
            <p class="-body">Pas grand chose au final, et c’était mon cas.</p>
          </div>
          <div class="p-nightcall__intro-nightcall5-right">
            <p class="-body">Mais cette fois-ci, c’était différent : ce que je vis à travers cette fenêtre éveilla en moi une petite flamme, reflet d’une envie d’essayer quelque chose d’inhabituel et de spontané, et mettre de côté rien qu’une nuit mon esprit lui aussi dans le brouillard.</p><br />
            <p class="-h4">Je me suis donc lancé.</p>
          </div>
        </div>
      </div>
    </section>
    <section class="p-nightcall__firstpart pt160 pb56">
      <div class="wrap">
        <h2 class="p-nightcall__firstpart-nightcall1 mb40" data-aos="fade-up">
        Premiers pas</h2>
        <p class="p-nightcall__firstpart-nightcall2 -quote mb24" data-aos="fade-up">
        Le trépied sur le dos, le réflexe en bandoulière, je partis de chez moi en mode aventure.</p>
        <div class="p-nightcall__firstpart-nightcall3" data-aos="fade-up">
          <p class="-body">La brume possède une caractéristique visuelle unique : <strong>elle renforce l’impression de perspective</strong>, même en photo.</p><br />
          <p class="-body">En absorbant la lumière progressivement, les éléments disparaissent selon leur position dans l’espace. </p>
        </div>
        <div class="p-nightcall__firstpart-nightcall4" data-aos="fade-up">
          <p class="-body">On distingue ainsi plus facilement <i>la profondeur</i>, sans perdre en netteté sur les différents <i>plans de l’image</i>. Jusqu’au tout dernier, <i>l'arrière-plan</i>, qui disparaît totalement.</p><br />
          <p class="-body">L’image se simplifie, mettant progressivement plus en avant les éléments <strong>qui apparaissent dans les premiers plans</strong>.</p>
        </div>
        <figure class="p-nightcall__firstpart-nightcall5 -lightcarrousel -tools-left -grid mt120" data-aos="fade-up">
          <div class="carousel">
            <div class="carousel__caption">Rue photographiée en sortant de chez moi.</div>
            <div class="carousel__buttons">
              <button class="carousel__buttons-btn" @click="handlePrev($refs.outofhome)">
                <svgicon name="16-arrow-left" />
              </button>
              <button class="carousel__buttons-btn" @click="handleNext($refs.outofhome)">
                <svgicon name="16-arrow-left" />
              </button>
            </div>
          </div>
          <agile :navButtons="false" ref="outofhome">
            <div class="slide">
              <img src="@/assets/nightcall/outofhome1.jpg" srcset="@/assets/nightcall/2x/outofhome1.jpg 2x" alt="Petite rue légèrement éclairée sous un brouillard épais">  
            </div>
            <div class="slide">
              <img src="@/assets/nightcall/outofhome2.jpg" srcset="@/assets/nightcall/2x/outofhome2.jpg 2x" alt="Même rue sous un plan différent">  
            </div>
            <div class="slide">
              <img src="@/assets/nightcall/outofhome3.jpg" srcset="@/assets/nightcall/2x/outofhome3.jpg 2x" alt="Croisement des deux rues principales sous un feu rouge">  
            </div>
          </agile>
        </figure>
      </div>
    </section>
    <section class="p-nightcall__secondpart pt64">
      <div class="wrap">
        <p class="p-nightcall__secondpart-nightcall1 -quote" data-aos="fade-up">
        Ainsi, il est plus facile d’avoir un contrôle assez précis de ce qu’il faut dévoiler et cacher, pour éviter les éléments visuels parasites qui rendraient confus le message ou l’idée à transmettre. </p>
        <span class="-separ mb160 mt120" />
        <h2 class="p-nightcall__secondpart-nightcall2 mb40" data-aos="fade-up">
        Visions</h2>
        <p class="p-nightcall__secondpart-nightcall3 -quote mb24" data-aos="fade-up">
        Non loin de chez moi existe un pont remarquable, faisant la jonction entre ma ville et celle voisine.</p>
        <div class="p-nightcall__secondpart-nightcall4 mt48" data-aos="fade-up">
          <p class="-body">Construit au début des années 1900, l’édifice a des allures de pont du <i>Golden Gate</i> miniaturisé, et son éclairage la nuit est particulièrement réussi.</p><br />
          <p class="-body">J’ai donc marché un peu, en direction de ce dernier, motivé par mon intuition. <strong>Et je peux dire qu’elle ne m’a pas déçu.</strong></p>
        </div>
      </div>
      <figure class="p-nightcall__secondpart-nightcall5 mt120 mb120" data-aos="fade-up">
        <img src="@/assets/nightcall/widebridge.jpg" srcset="@/assets/nightcall/2x/widebridge.jpg 2x" alt="Pont du Port à l'Anglais" />
      </figure>
      <div class="wrap">
        <p class="p-nightcall__secondpart-nightcall6 -time mb8" data-aos="fade-up">
          3h18.
        </p>
        <div class="p-nightcall__secondpart-nightcall7" data-aos="fade-up">
          <p class="-body">Le jeu des lumières sur ce bâtiment ancien au style industriel a réveillé de bons souvenirs cinématographiques.</p><br />
          <p class="-body">Des installations futuristes de <i>Blade Runner</i>, aux rues sombres de <i>Dark City</i>, en passant par un mélange des deux dans <i>Brazil</i>... Des ambiances autant magnifiques qu’inquiétantes.</p>
        </div>
        <div class="p-nightcall__secondpart-nightcall8" data-aos="fade-up">
          <p class="-body">Ici, métal, béton et pierre donnent un ton dur et sérieux à l’image, renforcé par le fait qu’ils apparaissent tous nettement, contrairement au reste du décor, plus étouffé.</p><br />
          <p class="-body">Cependant, ils ne sont pas grand chose face à l’immensité du vide qui les entoure, initié par la nuit et le brouillard et contrasté par les rares lueurs apparentes.</p>
        </div>
      </div>
      <div class="p-nightcall__secondpart-nightcall9 mt120 mb120">
        <div class="wrap">
          <div class="p-nightcall__secondpart-nightcall10 -lightcarrousel -tools-left -grid" data-aos="fade-up">
            <div class="carousel">
            <div class="carousel__caption">Vues d’ensemble du pont, sous différent angles.</div>
            <div class="carousel__buttons">
              <button class="carousel__buttons-btn" @click="handlePrev($refs.allthebridge)">
                <svgicon name="16-arrow-left" />
              </button>
              <button class="carousel__buttons-btn" @click="handleNext($refs.allthebridge)">
                <svgicon name="16-arrow-left" />
              </button>
            </div>
          </div>
          <agile :navButtons="false" ref="allthebridge" data-aos="fade-up">
            <div class="slide">
              <img src="@/assets/nightcall/allthebridge1.jpg" srcset="@/assets/nightcall/2x/allthebridge1.jpg 2x" alt="Vue du pont via le sentier du bas">  
            </div>
            <div class="slide">
              <img src="@/assets/nightcall/allthebridge2.jpg" srcset="@/assets/nightcall/2x/allthebridge2.jpg 2x" alt="Câbles de soutien du pont">  
            </div>
            <div class="slide">
              <img src="@/assets/nightcall/allthebridge3.jpg" srcset="@/assets/nightcall/2x/allthebridge3.jpg 2x" alt="Photo du pont">  
            </div>
            <div class="slide">
              <img src="@/assets/nightcall/allthebridge4.jpg" srcset="@/assets/nightcall/2x/allthebridge4.jpg 2x" alt="Autre photo des câbles de soutien">  
            </div>
          </agile>
          </div>
          <p class="p-nightcall__secondpart-nightcall11 -quote mt120 mb120" data-aos="fade-up">
            Et malgré cela, les deux se complètent parfaitement.<br />
            Sans la météo, la construction ne deviendrait qu’un détail dans cette jungle urbaine, et sans l’architecture, il n’y aurait pas de forme, pas de message évocateur.
          </p>
          <div class="p-nightcall__secondpart-nightcall12 -lightcarrousel -tools-right -grid" data-aos="fade-up">
            <div class="carousel">
              <div class="carousel__caption">Vues spécifiques de plusieurs endroits du pont.</div>
              <div class="carousel__buttons">
                <button class="carousel__buttons-btn" @click="handlePrev($refs.bridgedetails)">
                  <svgicon name="16-arrow-left" />
                </button>
                <button class="carousel__buttons-btn" @click="handleNext($refs.bridgedetails)">
                  <svgicon name="16-arrow-left" />
                </button>
              </div>
            </div>
            <agile :navButtons="false" ref="bridgedetails">
              <div class="slide">
                <img src="@/assets/nightcall/bridgedetails1.jpg" srcset="@/assets/nightcall/2x/bridgedetails1.jpg 2x" alt="Vue d'une des deux arches du pont via la route">  
              </div>
              <div class="slide">
                <img src="@/assets/nightcall/bridgedetails2.jpg" srcset="@/assets/nightcall/2x/bridgedetails2.jpg 2x" alt="Câbles
                  de soutien">  
              </div>
              <div class="slide">
                <img src="@/assets/nightcall/bridgedetails3.jpg" srcset="@/assets/nightcall/2x/bridgedetails3.jpg 2x" alt="Passage sombre le long des arches du pont">  
              </div>
            </agile>
          </div>
        </div>
      </div>
      <div class="wrap">
        <div class="p-nightcall__secondpart-nightcall7" data-aos="fade-up">
          <p class="-body">Pour arriver à ce niveau de détail tout en bénéficiant d’assez de lumière, je n’ai pris des photos qu’en prise longue.</p><br />
          <p class="-body">Cela devenait compliqué dans certains cas où l’attente devenait conséquente. En effet, de fines gouttelettes provenant de la brune se collaient sur le verre de l’objectif, créant de multiples flous et halos non-désirés.</p>
        </div>
        <div class="p-nightcall__secondpart-nightcall8" data-aos="fade-up">
          <p class="-body">J’ai dû donc compenser les paramètres de l’appareil pour réduire le temps de prise, tout en soufflant très légèrement sur le verre pendant certaines prises pour éviter les gouttelettes, et sans faire de buée.</p><br />
          <p class="-body">C’est un peu “bricolage” mais ça a fonctionné.</p>
        </div>
        <span class="-separ mt120" />
      </div>
    </section>
    <section class="p-nightcall__thirdpart">
      <div class="wrap">
        <h2 class="p-nightcall__thirdpart-nightcall1 mt160" data-aos="fade-up">
          Dystopie
        </h2>
        <h3 class="p-nightcall__thirdpart-nightcall2 -time mt48 mb8" data-aos="fade-up">
          3h50.
        </h3>
        <p class="p-nightcall__thirdpart-nightcall3 -quote mb24" data-aos="fade-up">
          Je continuais à déambuler, matériel en main, pour trouver d’autres plans à photographier.
        </p>
        <p class="p-nightcall__thirdpart-nightcall4" data-aos="fade-up">
          De rares personnes à pied croisaient mon chemin, rentrant de soirée ou allant travailler.
        </p>
        <p class="p-nightcall__thirdpart-nightcall5" data-aos="fade-up">
          Tandis que des véhicules faisaient de même, dans un va-et-vient tout aussi peu abondant mais néanmoins régulier, une image m’est apparue, que j’ai tout de suite immortalisée :
        </p>
      </div>
      <figure class="p-nightcall__thirdpart-nightcall6 mt120 mb120" data-aos="fade-up">
        <img src="@/assets/nightcall/aloneontheroad.jpg" srcset="@/assets/nightcall/2x/aloneontheroad.jpg 2x" alt="Une personne marchant de dos sur le pont" />
      </figure>
      <div class="wrap">
        <div class="p-nightcall__thirdpart-nightcall7" data-aos="fade-up">
          <p class="-quote">« As-tu déjà fait ces rêves Néo, qui semblent plus vrais que la réalité ? »</p><br />
          <p class="-quote">« Si tu étais incapable de sortir d'un de ces rêves, comment ferais-tu la différence entre le monde du rêve et le monde réel ? »</p>
        </div>
        <figure class="p-nightcall__thirdpart-nightcall8 -lightcarrousel -tools-bottom mt120 mb160" data-aos="fade-up">
          <agile :navButtons="false" ref="lightroad">
            <div class="slide">
              <img src="@/assets/nightcall/lightroad1.jpg" srcset="@/assets/nightcall/2x/lightroad1.jpg 2x" alt="Lumières sur la route du pont">  
            </div>
            <div class="slide">
              <img src="@/assets/nightcall/lightroad2.jpg" srcset="@/assets/nightcall/2x/lightroad2.jpg 2x" alt="Lumières au croisement de la fin du pont">  
            </div>
          </agile>
        </figure>
        <span class="-separ" />
      </div>
    </section>
    <section class="p-nightcall__fourthpart pt160 pb200">
      <div class="wrap">
        <h2 class="p-nightcall__fourthpart-nightcall1 mb40" data-aos="fade-up">
          Fin du voyage
        </h2>
        <h3 class="p-nightcall__fourthpart-nightcall2 -time mb8" data-aos="fade-up">
          4h27.
        </h3>
        <p class="p-nightcall__fourthpart-nightcall3 -quote mb24" data-aos="fade-up">
          Perpendiculaire au pont et sous ce dernier se trouvait un chemin de promenade, qui longeait la Seine sur une grande longueur.
        </p>
        <p class="p-nightcall__fourthpart-nightcall4 mt48" data-aos="fade-up">
          Je savais qu’il n’y avait aucun éclairage, hormis de petits lampadaires assez éloignés les uns des autres. C’est donc après avoir fait le tour du pont que j’y alla jeter un œil par curiosité.
        </p>
      </div>
      <figure class="p-nightcall__fourthpart-nightcall5 mt120 mb120" data-aos="fade-up">
        <img src="@/assets/nightcall/roadtolimbo.jpg" srcset="@/assets/nightcall/2x/roadtolimbo.jpg 2x" alt="Route de terre légèrement éclairée"/>
      </figure>
      <div class="wrap">
        <p class="p-nightcall__fourthpart-nightcall6 -quote" data-aos="fade-up">
          Détour éternel,<br />
          Que mille âmes ont emprunté,<br />
          Sans se retourner.</p>
        <figure class="p-nightcall__fourthpart-nightcall7 mt120 mb160" data-aos="fade-up">
          <img src="@/assets/nightcall/underthebridge.jpg" srcset="@/assets/nightcall/2x/underthebridge.jpg 2x" alt="Sous un pont en brume"/>
        </figure>
        <h3 class="p-nightcall__fourthpart-nightcall8 -time mb8" data-aos="fade-up">
          5h05.
        </h3>
        <p class="p-nightcall__fourthpart-nightcall9 -quote mb24" data-aos="fade-up">
          Mon voyage d’une nuit était enfin terminé, et je regagnais mon appartement aussi fier de moi qu’exténué.
        </p>
        <div class="p-nightcall__fourthpart-nightcall10 mt48" data-aos="fade-up">
          <p class="-body">La colorimétrie des images a été modifiée pour garder une <strong>cohérence globale</strong>. Aucun montage n’a été réalisé.</p><br />
          <p class="-body">Toutes les photos ont été prises à l’aide d’un appareil photo <i>CANON EOS 600D</i> et d’un objectif <i>TAMRON 28-75 mm SP AF f/2.8.</i></p>
        </div>
      </div>
    </section>
    <section class="p-nelson__nextprj">
      <ProjectCard
        :project="2"
        :inProject="true"
      />
    </section>
    <TheFooter :darkFooter="false" :darkMode="darkMode"/>
    <ScrollTop />
  </div>
</template>

<script>
import TheFooter from "@/components/TheFooter.vue"
import ScrollTop from '@/components/ScrollTop.vue'
import ProjectCard from '@/components/ProjectCard.vue'
import HeadProject from '@/components/HeadProject.vue'
import Splitting from "splitting"
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";

export default {
  name: 'Nightcall',
  components: {
    ProjectCard,
    ScrollTop,
    HeadProject,
    TheFooter
  },
  props: {
    darkMode: String
  },
  methods: {
    handlePrev(btn) {
      document.activeElement.blur();
      btn.goToPrev();
    },
    handleNext(btn) {
      document.activeElement.blur();
      btn.goToNext();
    }
  },
  mounted () {
    window.scrollTo(0, 0);
    document.title = 'Nightcall — Centis Menant'
    this.$emit('is-lifted');
    Splitting();
  }
}
</script>

<style lang="scss">
  @import '@/assets/var.scss';

  .p-nightcall {
    color: $white;

    &__intro {
      position: relative;
      background-color: #0B0B1A;
      z-index: 2;

      &-cover {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-color: $black;
        z-index: -1;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-image: linear-gradient(180deg, rgba(11,11,26,0.00) 25%, #0B0B1A 100%);
          z-index: 2;
        }
        
        & img {
          position: relative;
          max-width: 100%;
          max-height: 100rem;
          height: auto;
          opacity: 0.75;
          z-index: 1;
        }
      }

      &-nightcall1 {
        grid-column: 1 / span 12;
      }

      &-nightcall2 {
        grid-column: 1 / span 12;
        }

      &-nightcall3 {
        grid-column: 1 / span 5;
      }

      &-nightcall4 {
        @extend .-body;
        
        grid-column: 7 / span 5;
      }

      &-nightcall5 {
        grid-column: 1 / span 12;
        padding: $px80;
        background-image: linear-gradient(135deg, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0.00) 51%, rgba(255,255,255,0.05) 100%);
        border-radius: $px2;
        box-shadow: inset 0 0 0 $px1 $white5;
        grid-template-columns: repeat(13, 1fr);
        
        &-title {
          grid-column: 1 / span 13;
          text-align: center;
        }

        &-left {
          @extend .-body;
          grid-column: 1 / span 6;

          & .-body {
            font-style: italic;
          }
        }

        &-right {
          @extend .-body;
          grid-column: 8 / span 6;
          
          & .-body {
            font-style: italic;
          }

          & .-h4 {
            font-family: $serif;
            color: $white;
          }
        }
      }
    }

    &__firstpart {
      position: relative;
      background-color: #121119;
      z-index: 2;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: $px120;
        left: 0;
        right: 0;
        background-image: linear-gradient(-45deg, #2E142A 0%, #120E2E 51%, #0B0B1A 100%);
      }
      
      & .wrap {
        position: relative;
        z-index: 1;
      }

      &-nightcall1 {
        grid-column: 1 / span 12;

      }

      &-nightcall2 {
        grid-column: 1 / span 5;
      }

      &-nightcall3 {
        grid-column: 1 / span 5;
      }

      &-nightcall4 {
        grid-column: 7 / span 5;
      }

      &-nightcall5 {
        grid-column: 1 / span 12;
      }
    }

    &__secondpart {
      position: relative;
      background-color: #121119;
      z-index: 2;

      &-nightcall1 {
        grid-column: 3 / span 8;
      }

      &-nightcall2 {
        grid-column: 1 / span 12;
      }

      &-nightcall3 {
        grid-column: 1 / span 5;
      }

      &-nightcall4 {
        @extend .-body;

        grid-column: 7 / span 5;
      }

      &-nightcall5 {
        
        & img {
          width: 100%;
          box-shadow:
          0 -2.5rem 12.5rem -3.75rem rgba(104, 127, 255, 0.25),
          0 2.5rem 12.5rem -3.75rem rgba(255, 151, 104, .25);
        }
      }

      &-nightcall6 {
        grid-column: 1 / span 12;
      }

      &-nightcall7 {
        grid-column: 1 / span 5;
      }

      &-nightcall8 {
        grid-column: 7 / span 5;
      }

      &-nightcall9 {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: $px160;
          bottom: $px160;
          left: 0;
          right: 0;
          background-image: linear-gradient(-45deg, #141A2E 0%, #06050A 52%, #141A2E 100%);
        }
      }

      & .wrap {
        position: relative;
        z-index: 2;
      }

      &-nightcall10 {
        grid-column: 1 / span 12;
      }

      &-nightcall11 {
        grid-column: 3 / span 8;
      }

      &-nightcall12 {
        grid-column: 1 / span 12;
      }
    }

    &__thirdpart {
      position: relative;
      background-color: #121119;
      z-index: 2;

      &-nightcall1 {
        grid-column: 1 / span 12;
      }

      &-nightcall2 {
        grid-column: 1 / span 12;
        }

      &-nightcall3 {
        grid-column: 1 / span 5;
      }

      &-nightcall4 {
        @extend .-body;
        
        grid-column: 1 / span 5;
      }

      &-nightcall5 {
        @extend .-body;
        
        grid-column: 7 / span 5;
      }

      &-nightcall6 {
        
        & img {
          width: 100%;
          box-shadow:
          0 -2.5rem 12.5rem -3.75rem rgba(142, 104, 255, 0.25),
          0 2.5rem 12.5rem -3.75rem rgba(142, 104, 255, 0.25);
        }
      }

      &-nightcall7 {
        grid-column: 3 / span 8;
        text-align: center;
      }

      &-nightcall8 {
        grid-column: 1 / span 12;
        width: 100%;
      }
    }

    &__fourthpart {
      position: relative;
      background-color: #121119;
      z-index: 2;

      &-nightcall1 {
        grid-column: 1 / span 12;
      }

      &-nightcall2 {
        grid-column: 1 / span 12;
      }

      &-nightcall3 {
        grid-column: 1 / span 5;
      }

      &-nightcall4 {
        @extend .-body;

        grid-column: 7 / span 5;
      }

      &-nightcall5 {
        
        & img {
          width: 100%;
          box-shadow:
          0 -2.5rem 12.5rem -3.75rem rgba(104, 127, 255, 0.2),
          0 2.5rem 12.5rem -3.75rem rgba(104, 127, 255, 0.2);
        }
      }

      &-nightcall6 {
        grid-column: 3 / span 8;
        text-align: center;
      }

      &-nightcall7 {
        grid-column: 1 / span 12;
        width: 100%;
        
        & img {
          width: 100%;
          box-shadow:
          0 -3.75rem 10rem -6.25rem rgba(104, 127, 255, 0.25),
          0 3.75rem 10rem -6.25rem rgba(104, 127, 255, 0.25);
        }
      }


      &-nightcall8 {
        grid-column: 1 / span 12;
      }

      &-nightcall9 {
        grid-column: 1 / span 5;
      }

      &-nightcall10 {
        @extend .-body;

        grid-column: 7 / span 5;
      }
    }
    
    & .-time {
      @extend .-h4;

      font-weight: 600;
      font-style: italic;
      color: $white;
    }

    & .-quote {
      @extend .-h4;

      font-family: $serif;
      color: $white;
    }

    & .-separ {
      display: block;
      height: $px1;
      width: 100%;
      grid-column: 1 / span 12;
      background-color: $white5;
    }
      
    & .-body {
      color: $white50;
    }

    & .agile__list {
      box-shadow: 
      0 -3.75rem 5rem -80px rgba(104, 127, 255, 0.25),
      0 3.75rem 5rem -80px rgba(104, 127, 255, 0.25);
    }
  }

  @media screen and (max-width: $phone) {
    .p-nightcall {
      &__intro {
        &-nightcall5 {
          padding: $px40;
        }
      }
    }
  }

</style>