<template>
  <div class="page-not-found">
    <section data-aos="fade-in" class="page-not-found__content">
      <figure class="page-not-found__content--pics">
        <img src="@/assets/notfound.svg" alt="Vision d'une étoile 404 à travers une baie vitrée">
      </figure>
      <h1 class="page-not-found__content--title">
        Oups 🤭
      </h1>
      <p class="page-not-found__content--info -body">
        La page n'existe pas (ou plus). Veuillez retourner sur l'accueil pour continuer votre visite.
      </p>
      <a href="/" class="page-not-found__content--btn -p">
        Revenir à l'accueil
      </a>
    </section>
    <TheFooter :darkFooter="false" :darkMode="darkMode" />
  </div>
</template>

<script>
import TheFooter from '@/components/TheFooter.vue'

export default {
  name: 'PageNotFound',
  components: {
    TheFooter
  },
  props: {
    darkMode: String
  },
  mounted () {
    document.title = 'Oups, il y a un problème — Centis Menant'
    this.$emit('is-lifted');
    this.$emit('is-dark');
  }
}
</script>

<style lang="scss">
  @import '../assets/var.scss';

  .page-not-found {
    position: relative;
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    margin-top: -10rem;

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10rem 5rem 5rem;
      flex: 1 1 0;
      text-align: center;
      color: $grey-900;

      &--pics {
        max-width: 67.5rem;
        margin: 0 auto;
      }

      &--title {
        font-family: $lineal;
        font-weight: 600;
        margin: 0 auto 1.5rem;
      }

      &--info.-body {
        margin: 0 auto 3rem;
        color: $grey-700;
      }

      &--btn {
        color: $grey-700;
        font-weight: 600;
        margin: 0 auto 1.5rem;
        padding: 0.75rem 1.5rem;
        border-radius: 0.25rem;
        background-color: $grey-100;
        transition: all 0.3s;
        
        & svg {
          fill: $grey-400;
        }

        &:hover,
        &:focus,
        &:active {
          background: $grey-200;
          color: $grey-900;

          & svg {
            fill: $grey-900;
          }
        }
      }

    }

    & .thefooter {
      flex: 0 0 auto;
    }

  } 


  @media screen and (max-width: $phone) {

    .page-not-found {

      &__content {
        padding: 10rem 0;

        &--pics {
        }

        &--title {
          padding: 0 1.5rem;
          margin-top: 1.5rem;
        }

        &--info.-body {
          padding: 0 1.5rem;
        }

        &--btn {
        }
      }
    }
  }

  .darkmode {
    & .page-not-found {
      background-color: #121319;

      &__content {
        color: $white;

        &--info.-body {
          color: $white50;
        }

        &--btn {
          color: $white;
          background-color: $white5;
          box-shadow: inset 0 0 0 0 $white, 0 0 0 0 $white50, inset 0 0 0 0 $white20;
          
          & svg {
            fill: $white50;
          }

          &:hover,
          &:focus,
          &:active {
            background: $white20;
            box-shadow: inset 0 0 0 0.0625rem $white, 0 0 1.25rem -0.3125rem $white50, inset 0 0 0.625rem 0 $white20;

            & svg {
              fill: $white;
            }
          }
        }
      }
    } 
  }
</style>