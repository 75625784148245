<template>
  <router-link :to="projects[project].link" class="project-card" :class="projects[project].id + ' ' + (inProject ? '-in-project' : '-out-project')">
    <div class="project-card-in-project" v-if="inProject">
      <span>Projet suivant
        <svgicon icon="16-arrow-left"/>
      </span>
    </div>
    <span class="project-card-type -spaced" v-if="!inProject">{{ projects[project].type.join(', ') }}</span>
    <h3 class="project-card-title">{{ projects[project].title }}</h3>
  </router-link>
</template>

<script>
  export default {
    name: 'ProjectCard',
    props: {
      project: null,
      inProject: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        projects: [{
            id: 'csnelson',
            title: 'Gérer une entreprise à partir de sa stratégie.',
            type: ['Design produit'],
            link: '/manage-a-business-based-on-its-strategy'
          },
          {
            id: 'nightcall',
            title: 'Nightcall',
            type: ['Projet photographique'],
            link: '/nightcall'
          },
          {
            id: 'cswolcen',
            title: "Optimiser sa façon de jouer et ses connaissances d'un jeu-vidéo.",
            type: ['UI Design', 'Brand design'],
            link: '/wolcen-universe'
          },
          // {
          //   id: 'fuzlin',
          //   title: 'Rampe de lancement pour projets innovants',
          //   type: ['Branding', 'Product design'],
          //   link: '/fuzlin'
          // },
          // {
          //   id: 'kriptown',
          //   title: 'L’achat d’actions à portée de tous',
          //   type: ['Product design'],
          //   link: '/kriptown'
          // }
        ]
      }
    },
    mounted() {
      this.$emit('projects', this.projects.length)
    }
  }
</script>

<style lang="scss">
  @import '../assets/var.scss';
  
  .project-card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1 0 0;
    min-width: 22.5rem;
    color: $white;
    overflow: hidden;
    z-index: 3;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: background-position 3s ease-in-out, transform 2s ease-in-out, opacity 0.5s ease-in-out, box-shadow 0.5s;
      transform-origin: bottom right;
      transform: scale(1);
      opacity: 0.35;
      z-index: 2;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }

    &:hover::before, &:focus::before, &:active::before {
      transform: scale(1.15);
      transition: background-position 6s cubic-bezier(.17,.01,.66,1), transform 6s cubic-bezier(.17,.01,.66,1), opacity 0.5s ease-in-out, box-shadow 0.5s;
      opacity: 1;
    }

    &.csnelson {
      &::before {
        background: url('../assets/cs-nelson/cover.png') no-repeat;
        background-position: bottom 0 right 0;
        background-size: contain;
      }
    
      &::after {
        background-image: linear-gradient(225deg, #2760AD 0%, #2A2A7F 52%, #61399B 100%);
      }
        
      &:hover::before, &:focus::before, &:active::before {
        box-shadow: inset 0 0 $px200 0 rgba(96, 192, 252, 0.5); 
      }
    }

    &.nightcall {
      &::before {
        background: #000103 url('../assets/nightcall/roadtolimbo.jpg') no-repeat;
        background-position: bottom 0 right 0;
        background-size: contain;
      }
    
      &::after {
        background-image: linear-gradient(-45deg, #47173F 0%, #1B144F 51%, #181827 100%);
      }
    }

    &.cswolcen {
      &::before {
        background: url('../assets/cs-wolcen/global/ui-elem1@2x.png') no-repeat;
        background-position: bottom 0 right 0;
        background-size: contain;
      }
    
      &::after {
        background-image: linear-gradient(-45deg, #8C4400 0%, #730000 11%, #370303 55%, #140000 100%);
      }
        
      &:hover::before, &:focus::before, &:active::before { 
        box-shadow: inset 0 0 $px160 rgba(234,0,0,0.20);
      }
    }

    &.fuzlin {
      &::before {
        background: url('../assets/cover-fuzlin.svg') no-repeat;
        background-position: bottom $px-36 right $px-36;
        background-size: contain;
      }
    
      &::after {
        background-image: linear-gradient(-45deg, #20212B 0%, #13141E 48%, #2A2E3C 100%);
      }
        
      &:hover::before, &:focus::before, &:active::before { 
        box-shadow: inset 0 0 $px160 rgba(255, 255, 255, 0.25); 
      }
    }

    &.kriptown {
      &::before {
        background: url('../assets/cover-kriptown.svg') no-repeat;
        background-position: bottom $px-56 right $px-56;
        background-size: contain;
      }
    
      &::after {
        background-image: linear-gradient(-44deg, #350B4A 0%, #03114D 51%, #0B2C84 100%);
      }
        
      &:hover::before, &:focus::before, &:active::before { 
        box-shadow: inset 0 0 $px160 rgba(198, 92, 198, 0.351); 
      }
    }
      
    &-type.-spaced {
      position: relative;
      display: block;
      font-size: $px12;
      line-height: $px18;
      color: $white50;
      font-weight: 400;
      margin-bottom: $px8;
      margin: $px64 $px64 $px16;
      text-shadow: 0 0 1rem $black50;
      z-index: 2;
    }

    &-title {
      position: relative;
      display: block;
      font-weight: 300;
      margin: 0 $px64 $px200;
      text-shadow: 0 0 2rem $black;
      z-index: 2;
    }
    
    &:hover &-in-project,
    &:focus &-in-project,
    &:active &-in-project {
      color: $white;
      text-shadow: 0 0 1.25rem $white20;

      & span {
        transform: translateX($px-16);
        transition: color 0.5s, text-shadow 0.3s, transform 0.3s;
        
        & svg {
          transform: translateX($px30) translateY(-0.0625rem) rotateZ(180deg);
          fill: $white;
          transition: transform 0.3s, fill 0.5s;
        }
      }
    }
  }

  .-out-project {

    &.project-card {
      aspect-ratio: 1 / 1.25;
      max-width: 35rem;
      width: 100%;
      flex: 0 0 auto;
      margin: 0.75rem;
      border-radius: 0.25rem;
      
      &::before {
        transform-origin: bottom center;
        background-position: bottom center;
      }
    }
  }

  .-in-project {
    
    & .project-card {
      &-in-project {
        position: relative;
        display:flex;
        text-align: center;
        text-shadow: 0 0 1rem $black50;
        margin: $px120 0 0;
        font-weight: 400;
        font-size: $px14;
        line-height: $px24;
        text-transform: uppercase;
        letter-spacing: $px4;
        word-spacing: $px4;
        color: $white50;
        justify-content: center;
        align-items: center;
        z-index: 2;

        & span {
          position: relative;
          display: flex;
          transition: color 0.3s, text-shadow 0.3s, transform 0.5s;
          
          & svg {
            position: relative;
            align-self: center;
            margin-left: $px-16;
            height: $px16;
            width: $px16;
            transform: translateX(0) translateY(-0.0625rem) rotateZ(180deg);
            fill: transparent;
            transition: transform 0.5s, fill 0.3s;
          }
        }
      }

      &-type.-spaced {
        display: none;
        }

      &-title {
        text-align: center;
        font-size: $px48;
        line-height: $px60;
        margin: $px24 $px64 $px120;
      }
    }
  }

  @media screen and (max-width: $laptop) {
    .-out-project {
      &.project-card {
        width: 40vw;
        margin: 0.75rem;
        border-radius: 0.25rem;
      }
    }
  }

  @media screen and (max-width: $phone) {
    .project-card {
      &-type.-spaced {
        margin: $px40 $px40 $px16;
      }

      &-title {
        margin: 0 $px40 $px160;
      }
    }
    .-out-project {
      &.project-card {
        width: 90vw;
        margin: 2.5vw 5vw;
      }
    }
  }
</style>