<template>
  <footer class="thefooter" :class="darkFooter || darkMode === 'true' ? '-dark' : '-light'">
    <div class="thefooter__logo">
      <router-link to="/"><svgicon name="logo" /></router-link>
    </div>
    <div class="thefooter__credits -note">
      <p class="thefooter__credits-credit -note"><strong>© 2022 Centis Menant.</strong> Tous droits réservés.</p>
      <p class="thefooter__credits-info -note">Développé avec amour par mes ✋🤚.</p>
      <a href="mailto:9centis@gmail.com" class="thefooter__credits-mail -note">9centis@gmail.com</a>
      <p class="thefooter__credits-discord -note">sabre#3620 (@discord)</p>
    </div>
    <ul class="thefooter__social -note">
      <li>
        <a class="-note" href="https://linkedin.com/in/centismenant">
          Linkedin<svgicon name="16-lkd" />
        </a>
      </li>
      <li>
        <a class="-note" href="https://dribbble.com/centismenant">
          Dribbble<svgicon name="16-dbbb" />
        </a>
      </li>
      <li>
        <a class="-note" href="https://www.behance.net/centismenant">
          Behance<svgicon name="16-behance" />
        </a>
      </li>
      <li>
        <a class="-note" href="https://www.instagram.com/fahren9/">
          Instagram<svgicon name="16-insta" />
        </a>
      </li>
    </ul>
  </footer>
</template>

<script>
  export default {
    name: 'TheFooter',
    props: {
      darkFooter: Boolean,
      darkMode: String
    }
  }
</script>

<style lang="scss">
  @import '../assets/var.scss';

  .thefooter {
    position: relative;
    padding: $px80;
    display: flex;
    flex: 0 0 auto;
    z-index: 50;
    transition: box-shadow 0.3s, background-color 0.3s;

    & a {
      display: inline-block;
      transition: color 0.3s;
    }


    &__logo {
      flex: 0 0 auto;
      margin-right: $px40;
      margin-top: $px2;
      
      & svg {
        height: 2.5rem;
        aspect-ratio: 50 / 40;
        width: 3.125rem;
        transition: fill 0.3s;
      }
    }

    &__credits {
      max-width: 18.75rem;
      
        &-credit {
          display: block;
        }

        &-info {
          display: block;
          margin-bottom: $px8;
        }

        &-mail {
          display: block;
        }

        &-discord {
          display: block;
        }
    }

    &__social {
      display:flex;
      text-align: right;
      flex-direction: column;
      align-items: flex-end;
      margin-top: $px-4;
      flex: 1 1;

      & li {
        display:flex;
        align-items: center;

        & a {
          padding: $px4 $px8; 

          & svg {
            height: 1rem;
            width: 1rem;
            transform: translateY(3px);
            margin-left: $px8;
            transition: fill 0.3s;
          }
        }
      }
    }
  }

  .-dark {
    box-shadow: none;
    background-color: $dark-800;

    & a {
      color: $white50;

      &:hover,
      &:focus,
      &:active {
        color: $white;
      }
    }

    & .thefooter__logo { 
      & svg {
        fill: $white50;
      }

      &:hover svg,
      &:focus svg,
      &:active svg {
        fill: $white;
      }
    }

    & .thefooter__credits {

      & > * {
        color: $white50;
        transition: color 0.3s;
      }
    }

    & .thefooter__social {
      & li {
        & a {
          & svg {
            fill: $white50;
          }

          &:hover svg,
          &:focus svg,
          &:active svg {
            fill: $white;
          }
        }
      }
    }
  }

  .-light {
    margin-top: -0.0625rem;
    box-shadow: inset 0 0.0625rem 0 0 $grey-200;

    & a {
      color: $grey-600;

      &:hover,
      &:focus,
      &:active {
        color: $grey-900;
      }
    }

    & .thefooter__logo { 
      & svg {
        fill: $grey-600;
      }

      &:hover svg,
      &:focus svg,
      &:active svg {
        fill: $grey-900;
      }
    }

    & .thefooter__credits {

      & > * {
        color: $grey-600;
        transition: color 0.3s;
      }
    }

    & .thefooter__social {
      & li {
        & a {
          & svg {
            fill: $grey-600;
          }

          &:hover svg,
          &:focus svg,
          &:active svg {
            fill: $grey-900;
          }
        }
      }
    }
  }

  @media screen and (max-width: $phone) {
    .thefooter {
      padding: $px80;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      &__logo {
        margin: 0 0 $px40 0;
      }

      &__credits {
        max-width: 18.75rem;
        
          &-credit {
            & strong {
              display: block;
            }
          }

          &-info {
            margin-bottom: $px16;
          }

          &-mail {
            display: block;
          }

          &-discord {
            display: block;
          }
      }

      &__social {
        text-align: center;
        align-items: center;
        margin-top: $px40;

        & li {
          display:flex;
          align-items: center;

          & a {
            padding: $px4 $px8; 

            & svg {
              height: 1rem;
              width: 1rem;
              transform: translateY(3px);
              margin-left: $px8;
              transition: fill 0.3s;
            }
          }
        }
      }
    }
  }
</style>