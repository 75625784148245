import Vue from 'vue'
import App from './App.vue'
import router from './router'
import SvgIcon from 'vue-svgicon'
import VueAgile from 'vue-agile'
import VueSilentbox from 'vue-silentbox'
import VuePageTransition from 'vue-page-transition' 
import AOS from 'aos'
import 'aos/dist/aos.css'

AOS.init({
  offset: 0,
  duration: 1000,
  once: true
});

Vue.config.productionTip = false

Vue.use(SvgIcon)
Vue.use(VueAgile)
Vue.use(VueSilentbox)
Vue.use(VuePageTransition)

import './assets/icons/16-dbbb'
import './assets/icons/16-fb'
import './assets/icons/16-lkd'
import './assets/icons/16-insta'
import './assets/icons/16-mail'
import './assets/icons/16-arrow-left'
import './assets/icons/16-pinterest'
import './assets/icons/16-behance'
import './assets/icons/16-moon'
import './assets/icons/logo'

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
