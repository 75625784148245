import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import About from '../views/About.vue'

import ManageABusinessBasedOnItsStrategy from '../views/Projects/Manage-a-business-based-on-its-strategy.vue'
import Nightcall from '../views/Projects/Nightcall.vue'
import WolcenUniverse from '../views/Projects/Wolcen-universe.vue'
import PageNotFound from '../views/PageNotFound.vue'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/manage-a-business-based-on-its-strategy',
    name: 'ManageABusinessBasedOnItsStrategy',
    component: ManageABusinessBasedOnItsStrategy
  },
  {
    path: '/nightcall',
    name: 'Nightcall',
    component: Nightcall
  },
  {
    path: '/wolcen-universe',
    name: 'WolcenUniverse',
    component: WolcenUniverse
  },
  { 
    path: "*",
    name: 'PageNotFound',
    component: PageNotFound
  }
]

const router = new VueRouter({
  routes
  // scrollBehavior: (to, from, savedPosition) => new Promise((resolve) => {
  //   const position = savedPosition || {};
  //   if (!savedPosition) {
  //     if (to.hash) {
  //       position.selector = to.hash;
  //     }
  //     if (to.matched.some((m) => m.meta.scrollToTop)) {
  //       position.x = 0;
  //       position.y = 0;
  //     }
  //   }
  //     router.app.$root.$once('triggerScroll', () => {
  //     router.app.$nextTick(() => resolve(position));
  //   });
  // })
})

export default router
