<template>
  <button
    type="button"
    @click="ScrollTo"
    class="scrolltop"
    data-aos="zoom-in"
    data-aos-once="false"
    data-aos-id="scrolltop"
    data-aos-offset="400"
    title="Revenir en haut"
  >
    <span>☝️</span>
  </button>
</template>

<script>
  export default {
    name: 'ScrollTop',
    methods: {
      ScrollTo() {
        document.activeElement.blur();
        window.scrollTo({top: 0, behavior: 'smooth'});
      }
    },
  }
</script>

<style lang="scss">
  @import '@/assets/var.scss';

  .scrolltop {
    border: none;

    position: fixed;
    display: flex;
    bottom: 1rem;
    right: 1rem;
    height: 3rem;
    width:3rem;
    background-color: $black50;
    box-shadow: 0 0 0 0.125rem $white10;
    align-items: center;
    justify-content: center;
    border-radius: 0.1875rem;
    transition: all 0.3s !important;
    cursor: pointer;
    z-index: 100;

    &:hover,
    &:focus,
    &:active {
      box-shadow: 0 0 0 0.125rem $white;
      background-color: $black;
    }

    & > span {
      @extend .-body;

      transform-origin: center;
      transition: transform 0.3s;
    }

    &:hover > span,
    &:focus > span,
    &:active > span {
      transform: scale(1.2);
    }
  }
</style>