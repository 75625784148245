/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'logo': {
    width: 50,
    height: 40,
    viewBox: '0 0 50 40',
    data: '<path pid="0" d="M27.57 0c.601 0 1.633.433 1.682 1.684l.002.095-.001.063v.026l-.002.012c-.092 1.837-2.639 2.102-2.467 1.717.544-1.24-9.67.88-18.474 12.203C.667 25.673 2.51 33.621 5.773 35.725c3.263 2.105 7.541 1.736 11.876-.709.143-.083.28-.165.41-.245 2.538-1.562 2.792-2.49 2.8-2.779a.226.226 0 01-.01-.075c-.069-.435-.033-1.597.013-2.296.05-.78.085-1.37.141-2.022l.007-.082.004-.038.008-.096c.005-.056.01-.096.01-.116l.002-.015c.2-2.18.49-4.089.806-5.624.5-2.444 1.063-3.944 1.415-4.1.65-.285 1.136-.026 1.593.501.458.527 1.189 1.126.964 1.697-.224.57-.344.832-.098.758.841-.248 3.1-4.924 4.032-5.197.617-.18 1.222.209 1.796 1.072.076.115.147.22.213.317l.18.27c.298.458.413.737.264 1.173-.203.593-.278.85-.036.793 1.038-.349 4.157-4.629 5.043-4.836.886-.208 1.537.52 2.01 1.416l.172.322c.203.388.3.64.243 1.18-.035.328-.991 1.6-1.479 2.475-1.993 3.58-3.37 4.803-3.363 8.058-.002.52 1.556-1.25 5.343-2.662 1.514-.564 2.77-.819 3.7-.928a8.558 8.558 0 011.228-.093 1.792 1.792 0 011.936 1.774c.003.054.004.109.004.165-.035 2.071-2.878 2.325-2.683 1.908.07-.153-.025-.244-.266-.3l-.165-.01-.332-.024c-1.888-.13-3.978-.216-8.307 1.576-3.105 1.286-3.317-.965-3.37-1.923-.054-.99.07-2.024.494-3.51.44-1.546 1.417-2.86 1.136-2.86-1.828 0-3.489 2.387-4.01 5.63-.167 1.04-.208 1.535-.22 2.29.016.366.102.497.237.436.35-.162.196 2.14-1.422 2.317a1.983 1.983 0 01-.317.025c-1.439 0-1.535-1.258-1.529-2.162.006-.905.047-1.69.281-3.383.379-2.734.988-4.051.79-4.051-.755 0-2.169 1.724-2.814 3.702-.068.276-.14.543-.216.803-.472 2.125-.353 3.351-.418 4.415-.02.612-.123 1.126-.372 1.49-.35.627-10.634 11.762-19.968 6.386-10.682-7.273 5.49-28.504 14.053-34.309 5-3.145 7.592-4.2 9.891-4.228zm11.854 31.707c.861-.524 1.934-.287 2.395.529.462.815.071 1.99-.79 2.514-1.396.688-2.266-.192-2.499-.865-.232-.674.071-1.593.894-2.178z" _fill="#1D1E26" fill-rule="evenodd"/>'
  }
})
