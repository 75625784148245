<template>
  <div id="app">
    <TheHeader
      :darkmode="darkMode"
      @change-mode="handleMode"
      @is-lifted="isLifted"
      :lift="lift"
    />
    <vue-page-transition name="fade">
      <router-view
        :lift="lift"
        :darkMode="darkMode"
        @handle-lifted="handleLifted"
        @is-lifted="isLifted"
        @is-dark="darkFooter = true"
        @is-light="darkFooter = false"
      />
    </vue-page-transition>
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader.vue"

export default {
  components: {
    TheHeader
  },
  data() {
    return {
      lift: true,
      darkFooter: false,
      darkMode: localStorage.getItem('darkmode')
    }
  },
  methods: {
    isLifted() {
      this.lift = true;
    },
    handleLifted() {
      this.lift = !this.lift;
    },
    handleMode(string) {
      this.darkMode = string;
    }
  },
  mounted() {
    if(this.darkMode) {
      if(this.darkMode === 'true') {
        document.getElementById('app').classList.add('darkmode')
      }
    } else {
      localStorage.setItem('darkmode', 'false')
    }
  }
}
</script>

<style lang="scss">
@import 'assets/var.scss';

#app {
    transition:
      color 0.3s,
      background-color 0.3s
    ;
  &.darkmode {
    background-color: $dark-800;
  }
}
</style>
