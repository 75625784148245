/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '16-moon': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M10 0c.934 0 1.83.16 2.663.454.287.101.275.646.009.817A7.993 7.993 0 009 8a7.995 7.995 0 003.81 6.816c.223.138.18.612-.086.708A8 8 0 1110 0z" _fill="#000" fill-rule="evenodd"/>'
  }
})
