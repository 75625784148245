<template>
  <header class="theheader">
    <nav class="theheader__nav">
      <div><router-link class="theheader__elem" to="/">Accueil</router-link></div>
      <!-- <li><router-link to="/extra">Extras</router-link> -->
      <div class="theheader__center">
        <router-link class="theheader__center--logo" to="/">
          <svgicon name="logo" />
        </router-link>
      </div>
      <div>
        <router-link :class="lift ? '' : '-land'" class="theheader__elem" to="/about">À propos</router-link>
        <button
          @click="handleColorMode"
          class="theheader__colormode"
          :class="lift ? '' : '-land'"
          type="button"
          :title="darkmode ? 'Activer le mode clair' : 'Activer le mode sombre'"
        >
          <svgicon name="16-moon"/>
        </button>
      </div>
    </nav>
  </header>
</template>

<script>

export default {
  name: 'TheHeader',
  props: {
    lift: null,
    darkmode: null
  },
  methods: {
    handleColorMode() {
      if(this.darkmode && this.darkmode == 'true') {
        this.$emit('change-mode', 'false')
        localStorage.setItem('darkmode', 'false');
        document.getElementById('app').classList.remove('darkmode');
      } else {
        this.$emit('change-mode', 'true')
        localStorage.setItem('darkmode', 'true');
        document.getElementById('app').classList.add('darkmode');
      }

      document.activeElement.blur();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../assets/var.scss';

.theheader {
  position: relative;
  z-index: 9;

  &__nav {
    padding: $px56 $px40;
    display: flex;
    justify-content: space-between;

    & > * {
      display: flex;
      flex: 1 1;
      align-items: center;
    
      &:last-child {
        justify-content: flex-end;
      }
    }
  }

  &__center {
    flex: 0 0 auto;
    
    & svg {
      height: 2.5rem;
      aspect-ratio: 50 / 40;
      width: 3.125rem;
      fill: $grey-600;
      transition: fill 0.3s;

      &:hover,
      &:focus,
      &:active {
        fill: $grey-900;
      }
    }

    & .router-link-exact-active svg {
      fill: $grey-900;
    }
  }

  &__colormode {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 0.25rem;
    background-color: transparent;
    transition: all 0.3s;
    width: 3rem;
    height: 3rem;
    cursor: pointer;


    & svg {
      fill: $grey-700;
      width: 1rem;
      height: 1rem;
      transition: all 0.3s;
      transform: scale(1);
      filter: none;
    }

    &:hover, 
    &:focus, 
    &:active {
      background-color: $grey-200;

      & svg {
        transform: scale(1.2);
        fill: $grey-900;
      }
    } 

    &.-land {
      
      & svg {
        fill: $black50;
        width: 1rem;
        height: 1rem;
        transition: all 0.3s;
      }

      &:hover, 
      &:focus, 
      &:active {
        background-color: $black10;

        & svg {
          fill: $grey-900;
        }
      } 
    }
  }
   
  &__elem {
    position: relative;
    z-index: 0;
    
    font-family: $lineal;
    color: $grey-600;
    font-weight: 600;
    font-size: $px18;
    line-height: $px24;
    transition: color 0.3s;
    padding: $px12 $px24;

    &.-land {
      color: $black50;
    }

    &:hover,
    &:focus,
    &:active {
      color: $grey-900;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0.3125rem;
      left: 90%;
      right: 0%;
      height: $px2;
      background-color: $grey-900;
      z-index: -1;
      transition: right 0.2s ease-in, left 0.2s ease-in, opacity 0.2s ease-in;
      transform-origin: 0% 100%;
      opacity: 0;
    }

    &:hover::before, &:focus::before, &:active::before {
      animation: slideLinkIn 0.3s ease-out;
      right: 20%;
      left: 20%; 
      opacity: 1;
      transform-origin: 100% 100%;
    }

    @keyframes slideLinkIn {
      from { right: 90%; left: 0%; opacity: 0; }        
      to { right: 20%; left: 20%; opacity: 1; }
    }

    &.router-link-exact-active {
      color: $grey-900;
      cursor: text;

      &::before {
        right: 20%;
        left: 20%; 
        opacity: 1;
      }

      &:hover::before, &:focus::before, &:active::before {
        animation: none;
      }
    }
  }
}

@media screen and (max-width: $phone) {
  .theheader {
    &__nav {
      padding: $px80 $px16 $px40;
    }

    &__colormode {
      position: absolute;
      left: 0;
      top:0.5rem;
      border-radius: 0;
      width: 100vw;
      height: 3rem;
    }
  }
}


.darkmode {
  .theheader {

    &__center {
      
      & svg {
        fill: $white50;

        &:hover,
        &:focus,
        &:active {
          fill: $white;
        }
      }

      & .router-link-exact-active svg {
        fill: $white;
      }

      &--colormode {
        background-color: $white;
      }
    }

    &__colormode {

      & svg {
        fill: $white;
        filter: drop-shadow(0 0 0.75rem $white) drop-shadow(0 0 4px $white50);
      }

      &:hover, 
      &:focus, 
      &:active {
        background-color: $white5;

        & svg {
          fill: $white;
        }
      } 

    }
    
    &__elem {
      color: $white50;

      &.-land {
        color: $white50;
      }

      &:hover,
      &:focus,
      &:active {
        color: $white;
      }

      &::before {
        background-color: $white;
      }

      &.router-link-exact-active {
        color: $white;
      }
    }
  }
}

</style>
