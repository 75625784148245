/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '16-dbbb': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M11.818 13.666c-.07-.412-.434-2.417-1.33-4.88 2.206-.352 4.117.253 4.258.3a6.834 6.834 0 01-2.928 4.58zM8 14.833a6.805 6.805 0 01-4.353-1.567c.09.074.154.12.154.12s1.31-2.856 5.361-4.268c.015-.006.031-.01.046-.014.962 2.497 1.358 4.59 1.46 5.187A6.81 6.81 0 018 14.833zM1.168 8c0-.073.003-.144.005-.216.122.003 3.497.08 7.015-.973.195.382.383.773.554 1.161-.09.025-.18.052-.27.082-3.686 1.191-5.556 4.508-5.556 4.508l.002.004A6.806 6.806 0 011.168 8zm3.917-6.178a40.863 40.863 0 012.537 3.936c-3.28.872-6.128.837-6.308.834a6.834 6.834 0 013.771-4.77zm7.427 1.052c-.016.025-.99 1.507-3.569 2.473A34.713 34.713 0 006.39 1.36a6.805 6.805 0 016.123 1.513zm2.319 5.058c-.1-.022-2.412-.52-4.759-.224-.049-.117-.097-.234-.148-.352a20.533 20.533 0 00-.454-.991c2.698-1.102 3.793-2.685 3.806-2.704a6.805 6.805 0 011.555 4.27zm1.007-1.542a8.028 8.028 0 00-1.205-2.862 7.995 7.995 0 00-2.16-2.16 7.976 7.976 0 00-1.359-.739 8.016 8.016 0 00-8.771 1.715A8.054 8.054 0 00.629 4.886 7.96 7.96 0 000 8a7.92 7.92 0 00.63 3.115c.199.473.448.932.737 1.36.286.423.614.82.976 1.183a8.015 8.015 0 002.543 1.713c.483.205.99.36 1.503.465a7.96 7.96 0 003.222 0 7.88 7.88 0 002.862-1.203 8.123 8.123 0 001.184-.975c.362-.363.691-.76.976-1.184a7.988 7.988 0 001.205-6.084z" _fill="#000" fill-rule="evenodd"/>'
  }
})
