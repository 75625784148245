/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '16-mail': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M0 12.35V3.611a2.205 2.205 0 012.045-2.156L2.2 1.45h11.6c1.159 0 2.114.904 2.194 2.043L16 3.65v8.7a2.205 2.205 0 01-2.043 2.194l-.157.006H2.2a2.205 2.205 0 01-2.194-2.043L0 12.35zm14.5-7.26L8.43 9.34a.75.75 0 01-.76.059l-.1-.06L1.5 5.091v7.259c0 .351.266.647.606.694l.094.006h11.6a.705.705 0 00.694-.606l.006-.094V5.09zm-.7-2.14H2.2a.704.704 0 00-.615.369l6.414 4.49 6.416-4.49a.707.707 0 00-.504-.36L13.8 2.95z" _fill="#000" fill-rule="evenodd"/>'
  }
})
