/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '16-arrow-left': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M3.81 8.75l2.72 2.72a.75.75 0 01-1.06 1.06l-4-4a.75.75 0 010-1.06l4-4a.75.75 0 011.06 1.06L3.81 7.25H14a.75.75 0 110 1.5H3.81z" _fill="#000" fill-rule="evenodd"/>'
  }
})
