<template>
  <div class="p-nelson">
    <HeadProject
      titleFir="Gérer une entreprise"
      titleSec=" à partir de sa stratégie."
      :backline="true"
      type="Product Design"
      client="Nelson (via Fuzlin)"
      role="Product Designer"
      date="2017 - 2018"
    />
    <section class="p-nelson__cover" data-aos="fade-up">
      <img
        src="@/assets/cs-nelson/cover.png"
        srcset="@/assets/cs-nelson/2x/cover.png 2x"
        alt="Image de couverture"
        data-aos="fade-in"
        data-aos-delay="300"
      />
    </section>
    <section class="p-nelson__intro pt120">
      <div class="wrap">
        <p class="p-nelson__intro-csnelson1 -h4" data-aos="fade-up">
        Sortie initialement d’un cabinet de conseil, le concept du projet <i>Nelson</i> a vu le jour dans le but de permettre aux comités de direction de petites et moyennes entreprises d’avoir un accès abordable à des conseils en stratégie pour leur entreprise.</p>
        <div class="p-nelson__intro-csnelson2 -body mt48" data-aos="fade-up">
          <p class="-body">Le projet aura eu plusieurs tumultes, notamment de multiples changements de direction de produit pour finalement ne pas toucher un marché assez attractif.<br/>
          Mais là n’est pas le sujet.</p><br/>
          <p class="-body">Mon travail a été de me pencher sur l’une des dernières propositions d’outil, à savoir <strong>une plateforme permettant de gérer ses équipes à travers la stratégie fixée par son entreprise</strong>.</p>
        </div>
        <figure class="p-nelson__intro-csnelson3 mt120 mb120" data-aos="fade-up">
          <img src="@/assets/cs-nelson/locaux.jpg" srcset="@/assets/cs-nelson/2x/locaux.jpg 2x" alt="Dans les locaux de Nelson" class="-imgShadow"/>
        </figure>
        <div class="p-nelson__intro-csnelson4 -body mb30" data-aos="fade-up">
          <p class="-body">Avec mon collègue de longue date et associé <a class="-darklink" href="https://www.linkedin.com/in/lancelotp/" target="_blank">Lancelot Prigent</a>, développeur full stack, nous avons proposé une nouvelle direction stratégique à un produit balbutiant.</p><br/>
          <p class="-body">Nous voulions, bien entendu, garder le cœur de métier de Nelson <i>— le conseil en stratégie d’entreprise —</i> tout en l’associant à un univers dans la tendance du moment, à savoir <strong>la gestion de projet</strong>.</p>
        </div>
        <p class="p-nelson__intro-csnelson5 -body" data-aos="fade-up">
        L’objectif était <strong>d'attirer beaucoup plus de prospects, attiré par la gestion de projet, que nous pourrions convertir à l’avenir</strong>. Le produit d’appel était l’espace limité mais gratuit de gestion de projet, débloqué par un abonnement mensuel donnant un accès illimité à la plateforme, dont la partie conseil en stratégie qui était le produit phare.</p>
        <span class="-separ mb160 mt120" />
        <h2 class="p-nelson__intro-csnelson6 -h3 mb40" data-aos="fade-up">
        Gestion d’ensemble d’un projet</h2>
        <div class="p-nelson__intro-csnelson7 -body" data-aos="fade-up">
          <p class="-body">La conception du produit a été le fruit de l’inspiration de nombreux géants de la gestion de projet, à savoir <i>Trello</i>, <i>Asana</i>, <i>Jira</i> et <i>Monday</i>, pour ne citer qu’eux, tout en gardant en tête le contexte du produit : <strong>le conseil en stratégie d’entreprise</strong>.</p><br/>
          <p class="-body">Il fallait que la gestion des projets soit <strong>fluide</strong>, <strong>facile</strong>, <strong>lisible</strong> et <strong>flexible</strong>, adaptée pour des organisations de projets plus ou moins complexes.</p>
        </div>
        <div class="p-nelson__intro-csnelson8 mt120 -tools-top" :class="darkMode ? '-lightcarrousel' : ''" data-aos="fade-up">
          <div class="carousel">
            <div class="carousel__caption">Vue d’ensemble du panneau de gestion de projet.</div>
            <div class="carousel__buttons">
              <button class="carousel__buttons-btn" @click="handlePrev($refs.carousel)">
                <svgicon name="16-arrow-left" />
              </button>
              <button class="carousel__buttons-btn" @click="handleNext($refs.carousel)">
                <svgicon name="16-arrow-left" />
              </button>
            </div>
          </div>
          <agile :navButtons="false" ref="carousel">
            <div class="slide">
              <img src="@/assets/cs-nelson/1/manage-product-1.jpg" srcset="@/assets/cs-nelson/2x/1/manage-product-1.jpg 2x" alt="Gestion de projet complet">  
            </div>
            <div class="slide">
              <img src="@/assets/cs-nelson/1/manage-product-2.jpg" srcset="@/assets/cs-nelson/2x/1/manage-product-2.jpg 2x" alt="Création d'une carte projet vide">  
            </div>
            <div class="slide">
              <img src="@/assets/cs-nelson/1/manage-product-3.jpg" srcset="@/assets/cs-nelson/2x/1/manage-product-3.jpg 2x" alt="Création d'une carte projet complété">  
            </div>
            <div class="slide">
              <img src="@/assets/cs-nelson/1/manage-product-4.jpg" srcset="@/assets/cs-nelson/2x/1/manage-product-4.jpg 2x" alt="Gestion de projet en vue par dossiers">  
            </div>
            <div class="slide">
              <img src="@/assets/cs-nelson/1/manage-product-5.jpg" srcset="@/assets/cs-nelson/2x/1/manage-product-5.jpg 2x" alt="Gestion de projet en vue par dossiers ouverts">  
            </div>
          </agile>
        </div>
      </div>
    </section>
    <section class="p-nelson__firstpart pt160">
      <div class="wrap">
        <h2 class="p-nelson__firstpart-csnelson1 -h3 mb40" data-aos="fade-up">
        Flexibilité des cartes de projets</h2>
        <p class="p-nelson__firstpart-csnelson2 -body" data-aos="fade-up">
        La difficulté du travail d’UX a été de <strong>condenser un ensemble</strong> (très nombreux) <strong>de fonctionnalités</strong>, en veillant à respecter la volonté de tout bon UX designer qui se respecte : <strong>un outil simple, intuitif et agréable à utiliser</strong>.</p>
        <figure class="p-nelson__firstpart-csnelson3 -grid mt80 mb120" data-aos="fade-up">
          <figcaption class="-note mb16">Version condensée des cartes, avec un résumé visuel des données les concernantes.</figcaption>
          <img src="@/assets/cs-nelson/prj-card1.png" srcset="@/assets/cs-nelson/2x/prj-card1.png 2x" alt="Carte projet vide">
          <img src="@/assets/cs-nelson/prj-card2.png" srcset="@/assets/cs-nelson/2x/prj-card2.png 2x" alt="Carte projet remplie">
          <img src="@/assets/cs-nelson/prj-card3.png" srcset="@/assets/cs-nelson/2x/prj-card3.png 2x" alt="Carte projet enregistrée">
        </figure>
        <p class="p-nelson__firstpart-csnelson4 -body" data-aos="fade-up">
        Les cartes de projet peuvent représenter beaucoup de choses sur la plateforme : une tâche simple comme une plus complexe, une user story <i>(Scrum)</i>, un objectif à atteindre <i>(OKR)</i> ou plus simplement, un projet à mener à son terme.</p>
        <figure class="p-nelson__firstpart-csnelson5 -grid mt80 mb160" data-aos="fade-up">
          <img src="@/assets/cs-nelson/advanced-created-card.png" srcset="@/assets/cs-nelson/2x/advanced-created-card.png 2x" alt="Carte avancée"/>
          <figcaption class="-note mt16">Création avancée d’une carte de projet.</figcaption>
        </figure>
        <h3 class="p-nelson__firstpart-csnelson6 -h4 mb24" data-aos="fade-up">
        Cinquante nuances de projets</h3>
        <p class="p-nelson__firstpart-csnelson7 -body" data-aos="fade-up">
        Grâce au menu de droite, le créateur du projet a toutes les cartes en mains pour gérer son équipe, le projet, et ce de la façon dont il le souhaite.</p>
        <figure class="p-nelson__firstpart-csnelson8 -grid mt56 mb160" data-aos="fade-up">
          <img src="@/assets/cs-nelson/project_empty.png" srcset="@/assets/cs-nelson/2x/project_empty.png 2x" alt="Projet vide"/>
        </figure>
        <h3 class="p-nelson__firstpart-csnelson9 -h4 mb24" data-aos="fade-up">
        Un univers dans un univers</h3>
        <p class="p-nelson__firstpart-csnelson10 -body" data-aos="fade-up">
        Selon l’un des fondateurs, une seule carte de projet peut être créée pour <strong>gérer toute une entreprise</strong>, l’enjeu a été de rendre cela possible en évitant l’effet <i>« usine à gaz »</i>.</p>
        <ul class="p-nelson__firstpart-csnelson11 btn-list pt56" data-aos="fade-up">
          <li>
              <input
                :class="{ active: pickedPics === 'project_full_delete'}"
                type="radio" 
                v-model="pickedPics"
                id="project_full_delete"
                value="project_full_delete"
              >
            <label for="project_full_delete">
              Gestion des tâches
            </label>
          </li>
          <li v-for="pics in projectGallery" :key="pics.length">
              <input
                type="radio"
                v-model="pickedPics"
                :id="pics.value"
                :value="pics.value"
              >
            <label :for="pics.value">
              {{ pics.label }}
            </label>
          </li>
        </ul>
        <figure class="p-nelson__firstpart-csnelson12 mt40" data-aos="fade-up">
          <img :src="require(`@/assets/cs-nelson/2/${pickedPics}.png`)" :srcset="require(`@/assets/cs-nelson/2x/2/${pickedPics}.png`) + ' 2x'" alt="Ensemble de cartes projets"/>
        </figure>
      </div>
    </section>
    <section class="p-nelson__secondpart pt160">
      <div class="wrap">
        <h2 class="p-nelson__secondpart-csnelson1 -h3 mb40" data-aos="fade-up">
        Visualisations de la stratégie</h2>
        <div class="p-nelson__secondpart-csnelson2 -body" data-aos="fade-up">
          <p class="-body">On en vient maintenant au cœur de la proposition de Nelson : <strong>la stratégie d’entreprise</strong> et <strong>comment la visualiser de façon efficace à travers les cartes de projets que l’on crée</strong>.</p><br/>
          <p class="-body">Pour commencer, plusieurs types de visualisations étaient importantes à prendre en compte. Il faut pouvoir garder à l'œil un résumé de l’ensemble des informations présentes dans les cartes <i>(dates limites, état des cartes, le taux d’atteinte, l’indice de confiance, etc)</i> et une vue des projets dans un calendrier, à l’aide d’un <i>gantt</i>.</p>
        </div>
        <figure class="p-nelson__secondpart-csnelson3 -grid mt120 mb40" data-aos="fade-up">
          <img class="-imgShadow" src="@/assets/cs-nelson/strategy-dashboard.jpg" srcset="@/assets/cs-nelson/2x/strategy-dashboard.jpg 2x" alt="Capture d'écran du dashboard">
          <figcaption class="-note mt16">Vue d’ensemble (tableau) de l’état des projets.</figcaption>
        </figure>
        <figure class="p-nelson__secondpart-csnelson4 -grid mt40 mb120" data-aos="fade-up">
          <img class="-imgShadow" src="@/assets/cs-nelson/strategy-gantt.jpg" srcset="@/assets/cs-nelson/2x/strategy-gantt.jpg 2x" alt="Capture d'écran du gantt">
          <figcaption class="-note mt16">Même chose, version gantt.</figcaption>
        </figure>
      </div>
    </section>
    <section class="p-nelson__thirdpart pt160">
      <div class="wrap">
        <h2 class="p-nelson__thirdpart-csnelson1 -h3 mb40" data-aos="fade-up">
        Coach en stratégie</h2>
        <div class="p-nelson__thirdpart-csnelson2 -body" data-aos="fade-up">
          <p class="-body">Enfin, la dernière fonctionnalité importante : <strong>proposer des conseils en stratégie d’entreprise</strong> en prenant en compte les actions passées, effectuées sur la plateforme.</p><br/>
          <p class="-body">Cet outil n’a pas été terminé, il ne s’agira donc que d’ébauches. L’idée était d’avoir accès, en tant qu’utilisateur, à des conseils pour <strong>créer des cartes de projets allant dans le sens de la stratégie de l’entreprise</strong>.</p><br/>
          <p class="-body">Ces conseils sont directement proposés selon les cartes déjà créées, aux objectifs de l’entreprise, à son domaine d’activité et à des questions auxquelles il aurait répondu au préalable. Cette volonté d’automatiser le conseil était, selon nous, <strong>l’avenir de l’application</strong>.</p>
        </div>
        <figure class="p-nelson__thirdpart-csnelson3" data-aos="fade-up">
          <img class="-imgShadow" src="@/assets/cs-nelson/coach-icon.jpg" srcset="@/assets/cs-nelson/2x/coach-icon.jpg 2x" alt="Icône coach">
          <figcaption class="-note mt16">Icône de notification de conseil.</figcaption>
        </figure>
        <figure class="p-nelson__thirdpart-csnelson4 mt80 mb200" data-aos="fade-up">
          <img class="-imgShadow" src="@/assets/cs-nelson/advice.jpg" srcset="@/assets/cs-nelson/2x/advice.jpg 2x" alt="Page avec la modal conseil">
          <figcaption class="-note mt16">Affichage du conseil dans une page.</figcaption>
        </figure>
      </div>
    </section>
    <section class="p-nelson__fourthpart pt160">
      <div class="wrap">
        <h2 class="p-nelson__fourthpart-csnelson1 -h3 mb40" data-aos="fade-up">
        Conclusion</h2>
        <div class="p-nelson__fourthpart-csnelson2 -body" data-aos="fade-up">
          <p class="-body">Ce projet fut intéressant bien qu’un peu agité par les divers désirs stratégiques de ses fondateurs (un comble pour un outil de conseil en stratégie). Une bataille perpetuelle qui se concluera par un échec du produit.</p><br/>
          <p class="-body">Le travail de l’interface, tant sur le fond que sur la forme, a été riche d’enseignement, d’abord sur une thématique qui m’était jusqu’alors inconnue, mais également sur <strong>la gestion de la composition d’une interface complexe</strong>, du fait d’un (trop) grand nombre de fonctionnalités présentes.</p>
        </div>
        <div class="p-nelson__fourthpart-csnelson3 -body mt48" data-aos="fade-up">
          <p class="-body">Cependant, je ne vais pas me voiler la face : il y a <strong>quelques incohérences graphiques dans mon travail</strong>. La faute, je pense, au fait d’avoir priorisé <i>l’esthétique</i> sur le <i>fonctionnel</i>. Et également mon manque d’expérience. Surtout. Mine de rien, ce genre de chose s’apprend avec le temps, et je le remarque aujourd’hui, 4 ans plus tard.</p><br/>
          <p class="-body">Le travail que vous venez de voir a été conçu et réfléchi avec mon collègue développeur <a class="-darklink" href="https://www.linkedin.com/in/lancelotp/" target="_blank">Lancelot Prigent</a>, dont les nombreuses discussions ensemble ont permis d’en faire un ensemble <strong>plus cohérent</strong>, autant à développer pour lui qu’à utiliser pour les clients. </p><br/>
          <p class="-body">C’est une habitude que nous avons toujours eu tous les deux, qui est essentiel, selon moi, pour avoir <strong>un outil correctement conçu</strong>.</p>
        </div>
        <h3 class="p-nelson__fourthpart-csnelson4 -h4 mb40 mt120" data-aos="fade-up">
        Chantiers divers</h3>
        <figure class="p-nelson__fourthpart-csnelson5 mb200" :class="darkMode ? '-lightcarrousel' : ''" data-aos="fade-up">
          <agile :navButtons="false" ref="bonus">
            <div class="slide">
              <img src="@/assets/cs-nelson/3/_TTP.png" srcset="@/assets/cs-nelson/2x/3/_TTP.png 2x" alt="Ensemble de tooltips">  
            </div>
            <div class="slide">
              <img src="@/assets/cs-nelson/3/3-reunions_view.jpg" srcset="@/assets/cs-nelson/2x/3/3-reunions_view.jpg 2x" alt="Page de compte rendu de réunion">  
            </div>
            <div class="slide">
              <img src="@/assets/cs-nelson/3/8-analytics.jpg" srcset="@/assets/cs-nelson/2x/3/8-analytics.jpg 2x" alt="Page des analytics">  
            </div>
            <div class="slide">
              <img src="@/assets/cs-nelson/3/9-agenda.jpg" srcset="@/assets/cs-nelson/2x/3/9-agenda.jpg 2x" alt="Page agenda">  
            </div>
          </agile>
        </figure>
      </div>
    </section>
    <section class="p-nelson__nextprj">
      <ProjectCard
        :project="1"
        :inProject="true"
      />
    </section>
    <TheFooter :darkFooter="false" :darkMode="darkMode" />
    <ScrollTop />
  </div>
</template>

<script>
import TheFooter from '@/components/TheFooter.vue'
import ScrollTop from '@/components/ScrollTop.vue'
import ProjectCard from '@/components/ProjectCard.vue'
import HeadProject from '@/components/HeadProject.vue'
import Splitting from "splitting"
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";

export default {
  name: 'ManageABusinessBasedOnItsStrategy',
  components: {
    ProjectCard,
    ScrollTop,
    HeadProject,
    TheFooter
  },
  props: {
    darkMode: String
  },
  data () {
    return ({
      projectGallery: [
        {
          value: 'project-new_destination3',
          label: "Organisation de la carte"
        },
        {
          value: 'project-new_task_filled',
          label: "Création d'une tâche"
                
        },
        {
          value: 'project-objective_edit',
          label: "OKR"
        },
        {
          value: 'project-historic',
          label: "Historique"
        },
        {
          value: 'project-comment',
          label: "Commentaires"
        },
        {
          value: 'project-files',
          label: "Fichiers"
        },
        {
          value: 'project-gantt',
          label: "Gantt"
        },
        {
          value: 'project-state',
          label: "État du projet"
        },
        {
          value: 'project-dependance_empty',
          label: "Dépendance"
        },
        {
          value: 'project-suivi_manual_filled',
          label: "Suivi de projet"
        }     
      ],
      pickedPics: 'project_full_delete'
    })
  },
  methods: {
    handlePrev(btn) {
      document.activeElement.blur();
      btn.goToPrev()
    },
    handleNext(btn) {
      document.activeElement.blur();
      btn.goToNext()
    }
  },
  mounted () {
    window.scrollTo(0, 0);
    document.title = 'Gérer une entreprise à partir de sa stratégie — Centis Menant'
    this.$emit('is-lifted');
    Splitting();
  }
}
</script>

<style lang="scss">
  @import '../../assets/var.scss';

  .p-nelson {

    &__cover {
      position: relative;
      width: 100vw;
      background-image: linear-gradient(225deg, #2760AD 0%, #2A2A7F 52%, #61399B 100%);
      padding-top: $px24;
      display: flex;
      justify-content: center;
      z-index: 2;

      & > img {
        max-width: 66rem;
        width: 100%;
        max-height: 35.375rem;
      }
    }

    &__intro {
      position: relative;
      z-index: 5;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: $px120;
        right: 0;
        left: 0;
        background-color: $white;
        z-index: -1;
      }

      &-csnelson1.-h4 { 
        font-family: $serif;
        font-weight: 300;
        grid-column: 1 / span 5;
      }

      &-csnelson2 { 
        grid-column: 7 / span 5;
      }

      &-csnelson3 {
        grid-column: 1 / span 12;
        width: 100%;

        & img {
          width: 100%;
        }
      }

      &-csnelson4 { 
        grid-column: 1 / span 5;
      }

      &-csnelson5 { 
        grid-column: 7 / span 5;
      }

      &-csnelson6 { 
        grid-column: 1 / span 12;
      }

      &-csnelson7 { 
        grid-column: 1 / span 6;
      }

      &-csnelson8 { 
        grid-column: 1 / span 12;
      }
    }

    &__firstpart {
      position: relative;
      z-index: 4;

      &::before {
        content: '';
        position: absolute;
        top: $px-120;
        bottom: $px120;
        right: 0;
        left: 0;
        background-color: $grey-100;
        z-index: -1;
      }

      &-csnelson1 { 
        grid-column: 4 / span 6;
      }

      &-csnelson2 { 
        grid-column: 4 / span 6;
      }

      &-csnelson3 { 
        grid-column: 1 / span 12;

        & figcaption {
          grid-row-start: 1;
          grid-column: 2 / span 10;
          text-align: center;
        }

        & img {
          grid-row-start: 2;
          grid-column: span 4;
          width: 100%;
        }
      }

      &-csnelson4 { 
        grid-column: 4 / span 6;
      }

      &-csnelson5 {
        grid-column: 1 / span 12;

        & img {
          grid-column: 1 / span 12;
          grid-row-start: 1;
          width: 100%;
        }

        & figcaption {
          grid-column: 3 / span 8;
          grid-row-start: 2;
          text-align: center;
        }
      }
        
      &-csnelson6.-h4 { 
        grid-column: 4 / span 6;
        font-weight: 600;
      }

      &-csnelson7 { 
        grid-column: 4 / span 6;
      }

      &-csnelson8 {
        grid-column: 1 / span 12;

        & img {
          grid-column: 1 / span 12;
          grid-row-start: 1;
          width: 100%;
        }

        & figcaption {
          grid-column: 3 / span 10;
          grid-row-start: 2;
          text-align: right;
        }
      }
        
      &-csnelson9.-h4 { 
        grid-column: 4 / span 6;
        font-weight: 600;
      }

      &-csnelson10 { 
        grid-column: 4 / span 6;
      }

      &-csnelson11 { 
        grid-column: 2 / span 10;
        justify-content: center;
      }

      &-csnelson12 {
        grid-column: 1 / span 12;

        & img {
          grid-column: 1 / span 12;
          width: 100%;
        }
      }
    }

    &__secondpart {
      position: relative;
      color: $white;
      z-index: 3;

      &::before {
        content: '';
        position: absolute;
        top: $px-120;
        bottom: 0;
        right: 0;
        left: 0;
        background-image: linear-gradient(225deg, #2760AD 0%, #2A2A7F 52%, #61399B 100%);
        z-index: -1;
      }

      &-csnelson1 { 
        grid-column: 1 / span 12;
      }

      &-csnelson2 { 
        grid-column: 1 / span 6;

        p {
          color: $white;
          opacity: 0.75;
        }
      }

      &-csnelson3, &-csnelson4 { 
        grid-column: 1 / span 12;

        & img {
          grid-row-start: 1;
          grid-column: 1 / span 12;
          width: 100%;
        }

        & figcaption {
          grid-row-start: 2;
          grid-column: 1 / span 12;
          text-align: right;
        }
      }

    }

    &__thirdpart {
      position: relative;
      background-color: $white;
      z-index: 2;

      &-csnelson1 { 
        grid-column: 1 / span 12;
      }

      &-csnelson2 { 
        grid-column: 1 / span 6;
      }

      &-csnelson3 { 
        grid-column: 9 / span 4;

        & img {
          width: 100%;
        }

        & figcaption {
          text-align: right;
        }
      }

      &-csnelson4 { 
        grid-column: 1 / span 12;

        & img {
          width: 100%;
        }

        & figcaption {
          text-align: right;
        } 
      }
    }

    &__fourthpart {
      position: relative;
      z-index: 2;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: $grey-100;
        z-index: -1;
      }

      &-csnelson1 { 
        grid-column: 1 / span 12;
      }

      &-csnelson2 { 
        grid-column: 1 / span 5;
      }

      &-csnelson3 { 
        grid-column: 7 / span 5;
      }

      &-csnelson4.-h4 { 
        grid-column: 1 / span 12;
        text-align: center;
        font-weight: 600;
      }

      &-csnelson5 { 
        grid-column: 1 / span 12;
      }
    }

    & .-imgShadow {
      box-shadow: 0 $px12 $px32 $px-4 $black20;
    }

    & .-separ {
      display: block;
      height: $px1;
      width: 100%;
      grid-column: 1 / span 12;
      background-color: $grey-200;
    }
  }

  @media screen and (max-width: $phone) {
    .p-nelson {
      &__firstpart {
        &-csnelson3 {
          & img {
            width: 75%;
            margin: auto;
          }
        }
      }

      &__thirdpart {
        &-csnelson3 {
          margin-top: 4rem;
        }
      }
    }
  }

  .darkmode {
    & .p-nelson {

      &__intro {

        &::before {
          background-color: $dark-800;
        }

        &-csnelson1.-h4 { 
          color: $white;
        }

        &-csnelson2, &-csnelson4 { 
          & p {
            color: $white50;
          }
        }

        &-csnelson5 { 
          color: $white50;
        }

        &-csnelson6 { 
          color: $white;
        }

        &-csnelson7, &-csnelson8 {
          & p {
            color: $white50;
          }
        }
      }

      &__firstpart {
        
        &::before {
          background-color: $dark-700;
        }

        &-csnelson1 { 
          color: $white;
        }

        &-csnelson2 { 
          color: $white50;
        }

        &-csnelson3 { 
          & figcaption {
            color: $white50;
          }
        }

        &-csnelson4 { 
          color: $white50;
        }

        &-csnelson5 {
          & figcaption {
            color: $white50;
          }
        }
          
        &-csnelson6.-h4 { 
          color: $white;
        }

        &-csnelson7 { 
          color: $white50;
        }
          
        &-csnelson9.-h4 { 
          color: $white;
        }

        &-csnelson10 { 
          color: $white50;
        }
      }

      &__thirdpart {
        background-color: $dark-800;

        &-csnelson1 { 
          color: $white;
        }

        &-csnelson2 { 
          & p {
            color: $white50;
          }
        }

        &-csnelson3 {
          & figcaption {
            color: $white50;
          }
        }

        &-csnelson4 {
          & figcaption {
            color: $white50;
          } 
        }
      }

      &__fourthpart {
        &::before {
          background-color: $dark-700;
        }

        &-csnelson1 { 
          color: $white;
        }

        &-csnelson2,
        &-csnelson3 {
          & p {
            color: $white50;
          } 
        }

        &-csnelson4 {
          color: $white;
        }
      }

      & .-separ {
        background-color: $white20;
      }
    }

    @media screen and (max-width: $phone) {
      .p-nelson {
        &__firstpart {
          &-csnelson3 {
            & img {
              width: 75%;
              margin: auto;
            }
          }
        }

        &__thirdpart {
          &-csnelson3 {
            margin-top: 4rem;
          }
        }
      }
    }
  }
  </style>