<template>
  <div class="p-wolcen">
    <HeadProject
      titleFir="Optimiser sa façon de jouer"
      titleSec=" et ses connaissances d'un jeu-vidéo."
      :backline="true"
      type="UI Design, Brand Design"
      client="Personnel (via Fuzlin)"
      role="Lead Designer"
      date="Février 2021"
    />
    <section class="p-wolcen__intro pt200">
      <div class="wrap">
        <h2 class="p-wolcen__intro-wolcen1" data-aos="fade-up"  data-aos-delay="300">
        Contexte</h2>
        <h4 class="p-wolcen__intro-wolcen2 mt48 mb24" data-aos="fade-up"  data-aos-delay="300">
        En février 2020, un nouveau jeu de type hack ’n’ slash (dans le style d’un Diablo III ou Path of Exile) voyait le jour.</h4>
        <p class="p-wolcen__intro-wolcen3 -quote mb160" data-aos="fade-up"  data-aos-delay="300">
          <span class="-body">
            Développé par un studio français localisé à Nice, utilisant le moteur graphique Cry Engine 3, Wolcen: Lords of Mayhem sonnait déjà comme une petite pépite pour les amateurs du genre : son arbre de compétence - partie essentielle dans ce type de jeu - était composé de trois cercles concentriques, qui pouvaient être tournés à volonté, garantissant un nombre conséquent de façons de jouer.
          </span>
        </p>
      </div>
      <figure class="p-wolcen__intro-wolcen4 -picsShadow" data-aos="fade-up">
        <img src="@/assets/cs-wolcen/global/benchmark.jpg" srcset="@/assets/cs-wolcen/global/benchmark@2x.jpg 2x" alt="Benchmark de l'interface du jeu">  
        <figcaption class="-center -note mt16">Version condensée des cartes, avec un résumé visuel des données les concernantes.</figcaption>
      </figure>
      <div class="wrap">
        <div class="p-wolcen__intro-wolcen5 -grid mt160">
          <h4 class="p-wolcen__intro-wolcen5-left" data-aos="fade-up"  data-aos-delay="300">
            Mon associé Lancelot Prigent étant fan de jeux hack ‘n’ slash et habitué à développer des sites pour aider les joueurs à optimiser la qualité de leurs parties (il s’était déjà fait la main sur quelques jeux, notamment pour Conan Exiles et Satisfactory), il a décidé de travailler sur un site qui permettrait aux joueurs de créer des builds pour le jeu et de les partager à la communauté.
          </h4>
          <div class="p-wolcen__intro-wolcen5-right mt40" data-aos="fade-up"  data-aos-delay="300">
            <p class="-body mb24">
              Le jeu Wolcen était encore en cours de développement, notre objectif était de sortir très rapidement un site pour surfer sur la hype du lancement d’un jeu très prometteur.
            </p>
            <p class="-body mb24">
              J’ai accompagné Lancelot dans la gestion du produit, mais surtout dans la partie identité graphique du site et dans son interface.
            </p>
            <p class="-body">
              Nous savions déjà que le site ne serait pas qu’une simple plateforme de partage de builds, nous avions une vision beaucoup plus éloignée : un outil complet qui serait une deuxième interface au jeu, autant sur les builds que sur les équipements, l’histoire, les informations du jeu, etc. Pour résumer, le jeu serait là pour le divertissement et le site pour toutes recherches, réflexions et documentations.
            </p>
          </div>
        </div>
        <span class="-separ mt160" />
        <h4 class="p-wolcen__intro-wolcen6 mt120" data-aos="fade-up"  data-aos-delay="300">
          Vous voulions que les joueurs perçoivent l’énorme potentiel du site à travers son nom. J’en ai proposé deux à Lancelot pour commencer : Wolcen Universe.
          <br><br>Nous avions notre point de départ, suivi directement d'un logo.
        </h4>
      </div>
    </section>
    <section class="p-wolcen__firstpart pt80 pb160">
      <div class="wrap">
        <figure class="p-wolcen__firstpart-wolcen1" data-aos="fade-up">
          <img src="@/assets/cs-wolcen/global/logo-wu.png" srcset="@/assets/cs-wolcen/global/logo-wu@2x.png 2x" alt="Logo wolcen universe">
        </figure>
        <h2 class="p-wolcen__firstpart-wolcen2 mt160" data-aos="fade-up"  data-aos-delay="300">
        Jouer sur l’identité d’un jeu vidéo, version web</h2>
        <h4 class="p-wolcen__firstpart-wolcen3 mt48" data-aos="fade-up"  data-aos-delay="300">
        Parti pris</h4>
        <h4 class="p-wolcen__firstpart-wolcen4 mt24" data-aos="fade-up"  data-aos-delay="300">
        Avant de commencer à travailler sur l’interface, j’ai commencé à réfléchir à l’identité.</h4>
        <div class="p-wolcen__firstpart-wolcen5 mt24" data-aos="fade-up"  data-aos-delay="300">
          <div class="-grid">
            <p class="-body">
              D’un côté macro, je prenais des notes sur l’atmosphère que dégagerait le site, sur son ambiance, tout en récoltant un maximum d'images sur le jeu pour m’en inspirer.
            </p>
            <p class="-body">
              D’un côté plus micro, je commençais à travailler sur les visuels dominants du site, qui allaient marquer sa nature et qui méritaient de nombreux tests avant d’être parfaitement validés (le logo, l’image de fond, les boutons d’actions en faisant partie).
            </p>
          </div>
        </div>
        <div class="p-wolcen__firstpart-wolcen6 mt80" data-aos="fade-up">
            <figure>
              <img src="@/assets/cs-wolcen/global/ui-elem1.png" srcset="@/assets/cs-wolcen/global/ui-elem1@2x.png 2x" alt="Elément de UI 1">
            </figure>
            <figure>
              <img src="@/assets/cs-wolcen/global/ui-elem2.png" srcset="@/assets/cs-wolcen/global/ui-elem2@2x.png 2x" alt="Elément de UI 2">
            </figure>
        </div>
        <h4 class="p-wolcen__firstpart-wolcen4 mt80" data-aos="fade-up"  data-aos-delay="300">
        Une question importante s’est alors posée : </h4>
        <div class="p-wolcen__firstpart-wolcen5 mt24" data-aos="fade-up"  data-aos-delay="300">
          <div class="-grid">
            <p class="-body">
              Devais-je soit me démarquer visuellement du jeu, afin d’être original au risque de désorienter les utilisateurs, soit de copier le style graphique du jeu, ce qui ferait alors perdre la personnalité du site mais rendrait son utilisation plus intuitive ?
            </p>
            <p class="-body">
              Deux choix : deux avantages, deux inconvénients. J’ai donc opté pour une inspiration forte (pour primer le fonctionnel sur l’esthétique), tout en me permettant certaines libertés sur certains points, notamment sur les bonnes pratiques d’UX Web. Ainsi, le site serait un outil annexe au jeu : pas si éloigné dans le principe, mais totalement autonome en réalité.
            </p>
          </div>
        </div>
        <span class="-separ mt160" />
        <h4 class="p-wolcen__firstpart-wolcen3 mt160" data-aos="fade-up"  data-aos-delay="300">
        Logo et Design System</h4>
        <h4 class="p-wolcen__firstpart-wolcen7 mt24" data-aos="fade-up"  data-aos-delay="300">
        Pour mettre en place mes premières pistes d’identité, j’ai pris le fichier .sketch conçu pour chacun de nos projets à Fuzlin, à savoir le template de design system.</h4>
      </div>
      <figure class="p-wolcen__firstpart-wolcen8 -picsShadow mt120" data-aos="fade-up">
        <img src="@/assets/cs-wolcen/global/ds-vanilla.jpg" srcset="@/assets/cs-wolcen/global/ds-vanilla@2x.jpg 2x" alt="Template de design system de Fuzlin">
        <figcaption class="-center -note mt16">Capture d'écran de notre design system vanilla</figcaption>
      </figure>
      <div class="wrap">
        <div class="p-wolcen__firstpart-wolcen9 mt80" data-aos="fade-up"  data-aos-delay="300">
          <div class="-grid">
            <h4 class="-h4">
              Mon workflow est simple : je travaille manuellement certains éléments spécifiques, afin d’avoir rapidement un aperçu sur la cohérence visuelle de l’ensemble.
            </h4>
            <p class="-body mt40">
              Par exemple, je vais créer une page lambda dans laquelle j’y glisserai le fond, le logo, un titre, un corps de texte, une image, un input texte, un bouton et d’autres éléments qui seront importants et récurrents dans le projet en question.
              <br ><br >
              Je compose ensuite rapidement l’ensemble, comme si c’était une page web standard, et je la duplique pour tester des styles différents.
            </p>
          </div>
        </div>
        <figure class="p-wolcen__firstpart-wolcen10 -grid mt80" data-aos="fade-up">
          <img src="@/assets/cs-wolcen/global/modal1.png" srcset="@/assets/cs-wolcen/global/modal1@2x.png 2x" alt="Modal de connexion">
          <img src="@/assets/cs-wolcen/global/modal2.png" srcset="@/assets/cs-wolcen/global/modal2@2x.png 2x" alt="Modal d'alerte">
        </figure>
        <div class="p-wolcen__firstpart-wolcen11 -grid mt80" data-aos="fade-up"  data-aos-delay="300">
          <p class="-body">
            Pour ce projet, l’objectif était clair : créer une identité proche d’un jeu vidéo, en favorisant le code aux images, pour l’intégrer sans encombre à un design system (pour moi) et alléger les requêtes front du site (pour l’utilisateur).
            <br ><br >
            Cela donne des contraintes intéressantes, tout en requérant une bonne connaissance en css, en javascript et en Sketch.
          </p>
          <p class="-body mt40">
            J’ai opté pour des couleurs désaturées, à l’image d’un objet terni par la poussière et le temps. Quant aux fontes, il était nécessaire de retrouver une linéale en corps de texte (pour le confort de lecture), contrastée par une serif très graphique pour les titres, permettant de faire directement référence à l’univers du jeu.
            <br ><br >
            Une fois le style validé, j’ai initialisé le design system pour ensuite commencer à réfléchir à l’arborescence du site, puis à la composition de ses pages.
          </p>
        </div>
        <figure class="p-wolcen__firstpart-wolcen10 mt80" data-aos="fade-up">
          <img src="@/assets/cs-wolcen/global/typo.jpg" srcset="@/assets/cs-wolcen/global/typo@2x.jpg 2x" alt="Modal de connexion">
        </figure>
        <figure class="p-wolcen__firstpart-wolcen10 mt80" data-aos="fade-up">
          <img src="@/assets/cs-wolcen/global/colors.jpg" srcset="@/assets/cs-wolcen/global/colors@2x.jpg 2x" alt="Modal de connexion">
        </figure>
      </div>
    </section>
    <section class="p-wolcen__secondpart pt200 pb40">
      <div class="wrap">
        <h2 class="p-wolcen__secondpart-wolcen1" data-aos="fade-up"  data-aos-delay="300">
        Arborescence</h2>
        <h4 class="p-wolcen__secondpart-wolcen2 mt48" data-aos="fade-up"  data-aos-delay="300">
        Les bases du sites</h4>
        <div class="p-wolcen__secondpart-wolcen3 -grid mt24" data-aos="fade-up"  data-aos-delay="300">
          <p class="-body">
            Je ne vais pas rentrer dans les détails pour ces pages, elles ont été faites en dernier.
            <br><br>
            La raison principale étant que le cœur du site regroupait l’ensemble des fonctionnalités les plus importantes, si je commençais par ces pages le reste coulerait de source (niveau style et composition).
          </p>
          <p class="-h4 mt24">
            Je rentrerai plus dans le détail des maquettes de la partie suivante.
          </p>
        </div>
        <figure class="p-wolcen__secondpart-wolcen4 -picsShadow mt80" data-aos="fade-up">
          <img src="@/assets/cs-wolcen/desktop/home.jpg" srcset="@/assets/cs-wolcen/desktop/home@2x.jpg 2x" alt="Page d'accueil">
          <figcaption class="-center -note mt16">Page d’accueil</figcaption>
        </figure>
        <figure class="p-wolcen__secondpart-wolcen4 -picsShadow mt80" data-aos="fade-up">
          <img src="@/assets/cs-wolcen/desktop/builds.jpg" srcset="@/assets/cs-wolcen/desktop/builds@2x.jpg 2x" alt="Liste publiques des builds">
          <figcaption class="-center -note mt16">Page de liste des builds publiés</figcaption>
        </figure>
        <figure class="p-wolcen__secondpart-wolcen4 -picsShadow mt80" data-aos="fade-up">
          <img src="@/assets/cs-wolcen/desktop/profil_perso.jpg" srcset="@/assets/cs-wolcen/desktop/profil_perso@2x.jpg 2x" alt="Profil d'un utilisateur inscrit">
          <figcaption class="-center -note mt16">Page profil d’un utilisateur inscrit</figcaption>
        </figure>
      </div>
      <figure class="p-wolcen__secondpart-wolcen4 mt40" data-aos="fade-up">
        <img src="@/assets/cs-wolcen/desktop/log.png" srcset="@/assets/cs-wolcen/desktop/log@2x.png 2x" alt="Modal d'inscription et connexion">
      </figure>
    </section>
    <section class="p-wolcen__thirdpart pt160 pb160">
      <div class="wrap">
        <h2 class="p-wolcen__thirdpart-wolcen1" data-aos="fade-up">
          Au coeur de l’outil
        </h2>
        <div class="p-wolcen__thirdpart-wolcen2 mt40 -grid" data-aos="fade-up">
          <p class="-body">
            Venons-en à l’affichage et la construction des builds. Toute la difficulté a été de correspondre aux jeux pour ne pas perdre les utilisateurs dans la création de leur build tout en ayant notre propre petite touche de… Différence. Sauf que deux contraintes importantes sont apparues : nous sommes sur un outil, pas sur un jeu (le fonctionnel doit primer sur l’expérientiel). Et en prime, nous sommes sur un navigateur web, beaucoup moins puissant qu’un jeu vidéo.
          </p>
          <p class="-body">
            Il était obligatoire d’optimiser certains éléments afin de les rendre digérables par le navigateur et utilisable par le joueur. Tout en gardant un visuel satisfaisant.
          </p>
        </div>
        <span class="-separ mt160" />
        <h4 class="p-wolcen__thirdpart-wolcen3 mt160" data-aos="fade-up">
          1. Arbre, compétences et informations
        </h4>
        <p class="p-wolcen__thirdpart-wolcen4 -body mt24" data-aos="fade-up">
          Nous avons créé trois roues, correspondant respectivement aux trois niveaux de profondeur et de rotations possibles.
          <br><br>
          Le modèle géométrique a été construit sur Illustrator, puis importé sur Photoshop pour y appliquer la texture ainsi que des ombres portées.
        </p>
        <div class="p-wolcen__thirdpart-wolcen5 mt120" data-aos="fade-up">
          <figure class="-picsShadow mb40">
            <img src="@/assets/cs-wolcen/global/background.jpg" srcset="@/assets/cs-wolcen/global/background@2x.jpg 2x" alt="Background répétable">
            <figcaption class="-center -note mt16">Background répétable</figcaption>
          </figure>
          <figure class="-picsShadow mb40">
            <img src="@/assets/cs-wolcen/global/ring-lvl3.jpg" srcset="@/assets/cs-wolcen/global/ring-lvl3@2x.jpg 2x" alt="3ème niveau">
            <figcaption class="-center -note mt16">3ème niveau</figcaption>
          </figure>
          <figure class="-picsShadow mb40">
            <img src="@/assets/cs-wolcen/global/ring-lvl2.jpg" srcset="@/assets/cs-wolcen/global/ring-lvl2@2x.jpg 2x" alt="2ème niveau">
            <figcaption class="-center -note mt16">2ème niveau</figcaption>
          </figure>
          <figure class="-picsShadow mb40">
            <img src="@/assets/cs-wolcen/global/ring-lvl1.jpg" srcset="@/assets/cs-wolcen/global/ring-lvl1@2x.jpg 2x" alt="1er niveau">
            <figcaption class="-center -note mt16">1er niveau</figcaption>
          </figure>
        </div>
        <figure class="p-wolcen__thirdpart-wolcen6 -picsShadow" data-aos="fade-up">
          <img src="@/assets/cs-wolcen/global/wheel-full.jpg" srcset="@/assets/cs-wolcen/global/wheel-full@2x.jpg 2x" alt="Roue finale">
          <figcaption class="-center -note mt16">Roue finale</figcaption>
        </figure>
        <div class="p-wolcen__thirdpart-wolcen2 mt120 -grid" data-aos="fade-up">
          <p class="-body">
            D’autre part, les boutons, indiquants si une compétence a été activée, ont été travaillés pour être relativement similaires à ceux du jeu, mais uniquement réalisés en css.
            <br><br>
            En outre, il était nécessaire d’indiquer la nature d’une compétence (nom, description, particularité). Comme pour le jeu, nous nous sommes inspirés des tooltips présents dans ce dernier pour une certaine cohérence.
          </p>
          <p class="-body">
            Enfin, il ne restait plus que des éléments de fond, afin d’utiliser la roue sans problème et de pouvoir visualiser rapidement les informations importantes. À noter que le système d’utilisation de la roue en dessous est directement repris du jeu, pour éviter de perdre les joueurs.
          </p>
        </div>
        <figure class="p-wolcen__thirdpart-wolcen6 -picsShadow mt120" data-aos="fade-up">
          <img src="@/assets/cs-wolcen/desktop/build_wheel.jpg" srcset="@/assets/cs-wolcen/desktop/build_wheel@2x.jpg 2x" alt="Roue finale">
        </figure>
        <span class="-separ mt160" />
        <h4 class="p-wolcen__thirdpart-wolcen3 mt160" data-aos="fade-up">
          2. Composition de l’interface de base
        </h4>
        <div class="p-wolcen__thirdpart-wolcen2 mt24 -grid" data-aos="fade-up">
          <p class="-body">
            Si la partie de droite est dédiée à la visualisation de l’arbre des compétences, celle de gauche l’est pour le descriptif du build, ainsi que toute la partie sociale (votes, commentaires, partage, etc).
            <br><br>
            Nous avons beaucoup hésité entre un design immersif (comme l’actuel) ou quelque chose de plus standard (ranger verticalement, comme la plupart de sites web).
          </p>
          <p class="-body">
            Nous avons opté pour le premier afin de créer une véritable expérience d’utilisation, tandis qu’une petite voix dans ma tête me disait que l’utilisation deviendrait épuisante à force.
          </p>
        </div>
        <h4 class="p-wolcen__thirdpart-wolcen7 mt80" data-aos="fade-up"  data-aos-delay="300">
          Que voulez-vous, il y a des moments comme cela où on met l’anticipation de côté, et on a juste envie de se faire plaisir. 
        </h4>
        <figure class="p-wolcen__thirdpart-wolcen6 -picsShadow mt120" data-aos="fade-up">
          <img src="@/assets/cs-wolcen/desktop/build_top.jpg" srcset="@/assets/cs-wolcen/desktop/build_top@2x.jpg 2x" alt="Écran standard d’un build rempli">
          <figcaption class="-center -note mt16">Écran standard d’un build rempli</figcaption>
        </figure>
        <figure class="p-wolcen__thirdpart-wolcen6 -picsShadow mt40" data-aos="fade-up">
          <img src="@/assets/cs-wolcen/desktop/build_comment_answer.jpg" srcset="@/assets/cs-wolcen/desktop/build_comment_answer@2x.jpg 2x" alt="Commentaires (et la réponse à un commentaire)">
          <figcaption class="-center -note mt16">Même écran, côté commentaires (et la réponse à un commentaire)</figcaption>
        </figure>
        <div class="p-wolcen__thirdpart-wolcen2 mt120 -grid" data-aos="fade-up">
          <p class="-body">
            Cette partie de gauche possédait deux principales versions : lecture (ci-dessus) et édition (ci-dessous). La lecture est le format de consultation standard auquel tout le monde accédait.
            <br>
            Quant à l’édition, c’est celui qui permettait à l’auteur ou à un administrateur de modifier n’importe quel élément du build.
          </p>
          <p class="-body">
            Pour garder un aperçu réaliste entre édition et lecture, nous avons opté pour un éditeur type wysiwyg directement intégré à la page. Nul besoin d’aller sur une autre page pour avoir un aperçu, le rendu était déjà le bon.
          </p>
        </div>
        <figure class="p-wolcen__thirdpart-wolcen6 -picsShadow mt120" data-aos="fade-up">
          <img src="@/assets/cs-wolcen/desktop/my_build_edit_empty.jpg" srcset="@/assets/cs-wolcen/desktop/my_build_edit_empty@2x.jpg 2x" alt="Écran d’un build vide">
        </figure>
        <div class="p-wolcen__thirdpart-wolcen8 -grid mt120" data-aos="fade-up"  data-aos-delay="300">
          <p class="-body">
            Avec le recul, je me demande si c’était une bonne idée. Au-delà du temps de développement supplémentaire que cela a pris, il est parfois important pour l’utilisateur de scinder l’édition et l’aperçu, pour garder une concentration optimale. 
          </p>
          <p class="-h4">
            C’est une question que je me pose encore maintenant sur mes projets actuels.
          </p>
        </div>
        <span class="-separ mt160" />
        <h4 class="p-wolcen__thirdpart-wolcen3 mt160" data-aos="fade-up">
          3. Interface avancée
        </h4>
        <h4 class="p-wolcen__thirdpart-wolcen9 mt24" data-aos="fade-up">
          Ce que vous venez de voir n’était que la base d’un build. La porte d’entrée.
        </h4>
        <div class="p-wolcen__thirdpart-wolcen2 mt24 -grid" data-aos="fade-up">
          <p class="-body">
            Toute la partie intéressante pour un créateur / une créatrice de build, c’est de jongler entre les compétences, les attributs et les pouvoirs, puis d’avoir les statistiques correspondantes à chacune des modifications apportées.
            <br><br>
            Après tout, nous étions là pour optimiser son jeu ou lui permettre de trouver une nouvelle façon de jouer.
          </p>
          <p class="-body">
            Toujours dans cette idée de garder en arrière plan l’arbre des compétences, nous avons décidé de laisser la possibilité à l’utilisateur de masquer / afficher les éléments d’interface qu’il souhaitait, afin de correspondre à son besoin dans l’instant.
            <br><br>
            Par exemple, il pouvait masquer la partie textuelle à gauche pour n’afficher que les statistiques et les attributs à l’aide des boutons correspondants, ou bien choisir d’aller plutôt dans les compétences actives.
          </p>
        </div>
        <figure class="p-wolcen__thirdpart-wolcen6 -picsShadow mt120" data-aos="fade-up">
          <img src="@/assets/cs-wolcen/desktop/my_build_edit-attributes.jpg" srcset="@/assets/cs-wolcen/desktop/my_build_edit-attributes@2x.jpg 2x" alt="Partie écriture du build caché">
          <figcaption class="-center -note mt16">Partie écriture du build caché (bouton au centre à gauche) pour n’afficher que certains blocs d'informations</figcaption>
        </figure>
        <figure class="p-wolcen__thirdpart-wolcen6 -picsShadow mt40" data-aos="fade-up">
          <img src="@/assets/cs-wolcen/desktop/my_build_edit-skills.jpg" srcset="@/assets/cs-wolcen/desktop/my_build_edit-skills@2x.jpg 2x" alt="Même écran, sans les attributs et avec les compétences actives">
          <figcaption class="-center -note mt16">Même écran, sans les attributs et avec les compétences actives</figcaption>
        </figure>
        <figure class="p-wolcen__thirdpart-wolcen6 -picsShadow mt40" data-aos="fade-up">
          <img src="@/assets/cs-wolcen/desktop/my_build_edit-skills-selected.jpg" srcset="@/assets/cs-wolcen/desktop/my_build_edit-skills-selected@2x.jpg 2x" alt="Même écran, après avoir sélectionnée une compétence active (plus d’informations sur elle)">
          <figcaption class="-center -note mt16">Même écran, après avoir sélectionnée une compétence active (plus d’informations sur elle)</figcaption>
        </figure>
        <div class="p-wolcen__thirdpart-wolcen10 mt120 -grid" data-aos="fade-up">
          <p class="-h4">
            Ainsi, l’auteur pouvait transformer son interface au gré de son besoin.
          </p>
          <p class="-body mt40">
            Précédemment, je remettais en question le fait d’avoir adopté une composition immersive plutôt que standard, néanmoins je pense que la visualisation des statistiques ainsi que leur modification est le principal point fort ici : c’est qui fait de cette composition immersive une interface assez fonctionnelle. 
            <br>Toutes les données sont présentes, bien ordonnées, lisibles et assez faciles d’accès. 
          </p>
        </div>
        <span class="-separ mt160" />
        <h4 class="p-wolcen__thirdpart-wolcen3 mt160" data-aos="fade-up">
          4. Les extras
        </h4>
        <div class="p-wolcen__thirdpart-wolcen2 mt24 -grid" data-aos="fade-up">
          <p class="-body">
            Au-delà de tout cet aspect de performance, en termes de niveau, de pouvoir et d’attribut, il restait encore toute une partie à mettre en place pour une maîtrise optimale des statistiques du personnage : le stuff (l'équipement du personnage).
            <br><br>
            Toujours dans cette continuité d’être un outil indispensable pour les joueurs, l’équipement, même s’il était moins important que le build en lui-même, gardait une place prépondérante dans sa réalisation.
          </p>
          <p class="-body">
            Dans l’idée, rien de bien compliqué. Il s’agissait simplement d’une base de données retranscrivant tous les éléments d’équipements présents dans Wolcen.
            <br><br>
            Rien de plus qu’un tableau d’éléments, triable selon des critères de niveaux, caractéristiques, rareté, etc. Et une liste annexe à côté pour filtrer la catégorie d’équipement à afficher : casque, épée, bague, etc.
          </p>
        </div>
        <figure class="p-wolcen__thirdpart-wolcen6 -picsShadow mt120" data-aos="fade-up">
          <img src="@/assets/cs-wolcen/desktop/database.jpg" srcset="@/assets/cs-wolcen/desktop/database@2x.jpg 2x" alt="Base de données des équipements et armes du jeu">
          <figcaption class="-center -note mt16">Base de données des équipements et armes du jeu</figcaption>
        </figure>
        <div class="p-wolcen__thirdpart-wolcen8 -grid mt120" data-aos="fade-up"  data-aos-delay="300">
          <p class="-body">
            Nous pensions créer au fur et à mesure un wiki, afin de devenir un répertoire incontournable sur les informations de Wolcen (spoiler : ça ne s’est pas fait, plus d’info. dans la conclusion).
            <br><br>
            Mais avant cela, il était impératif d’avoir un blog pour les informations à la fois du jeu mais aussi de notre propre plateforme.
          </p>
          <p class="-h4">
            C’était loin d’être un travail colossal, mais je laisse juste des screens pour les curieux / curieuses.
          </p>
        </div>
        <figure class="p-wolcen__thirdpart-wolcen6 -picsShadow mt120" data-aos="fade-up">
          <img src="@/assets/cs-wolcen/desktop/blog.jpg" srcset="@/assets/cs-wolcen/desktop/blog@2x.jpg 2x" alt="Ecran d’accueil du blog">
          <figcaption class="-center -note mt16">Ecran d’accueil du blog</figcaption>
        </figure>
        <figure class="p-wolcen__thirdpart-wolcen6 -picsShadow mt40" data-aos="fade-up">
          <img src="@/assets/cs-wolcen/desktop/article.jpg" srcset="@/assets/cs-wolcen/desktop/article@2x.jpg 2x" alt="Ecran d’un article du blog">
          <figcaption class="-center -note mt16">Article du blog</figcaption>
        </figure>
      </div>
    </section>
    <section class="p-wolcen__fourpart pb160">
      <div class="wrap">
        <h2 class="p-wolcen__fourpart-wolcen1 mt160" data-aos="fade-up"  data-aos-delay="300">
        Version mobile</h2>
        <h4 class="p-wolcen__fourpart-wolcen2 mt40" data-aos="fade-up"  data-aos-delay="300">
        Nous pensions nous limiter à une interface exclusivement desktop et laptop (grands écrans).</h4>
        <div class="p-wolcen__fourpart-wolcen3 -grid mt24" data-aos="fade-up"  data-aos-delay="300">
          <p class="-body">
            En effet, le jeu lui-même étant sur grand écran, par extension c’était assez logique. Les analytics et retours d’utilisateurs nous ont appris le contraire.
          </p>
          <p class="-body">
            Certes, les créateurs de builds préféraient être sur grand écran, pour le confort global, la facilité de concentration et l’aperçu rapide de l’ensemble.
            <br>
            Cependant, ils ne correspondent qu’à une minorité de nos utilisateurs, même s’ils sont essentiels au bien-être de la plateforme.
          </p>
        </div>
        <div class="p-wolcen__fourpart-wolcen4 mt120" data-aos="fade-up">
          <figure class="-picsShadow mt120">
            <img src="@/assets/cs-wolcen/global/analytics-device.jpg" srcset="@/assets/cs-wolcen/global/analytics-device@2x.jpg 2x" alt="Analytics - Utilisateurs par catégorie d'appareils">
          </figure>
          <figure class="-picsShadow mt40">
            <img src="@/assets/cs-wolcen/global/analytics-sizes.jpg" srcset="@/assets/cs-wolcen/global/analytics-sizes@2x.jpg 2x" alt="Analytics - Résolutions d'écrans">
          </figure>
        </div>
        <div class="p-wolcen__fourpart-wolcen3 -grid mt120" data-aos="fade-up"  data-aos-delay="300">
          <p class="-body">
            Ce sont les utilisateurs / lecteurs qui sont les plus présents ; que ce soit pour le blog, les builds d’autres joueurs ou leurs propres builds.
          </p>
          <p class="-body">
            Dans ce cas précis, le mobile peut avoir une grande utilité. Je ne spéculerai pas sur les différentes hypothèses de cas et de lieux d’utilisation : c’était une réalité, et il était indispensable de rendre la plateforme totalement responsive.
          </p>
        </div>
        <h4 class="p-wolcen__fourpart-wolcen5 mt120" data-aos="fade-up"  data-aos-delay="300">
          Nous avons repensé l’ensemble de la plateforme à l’image d’une application mobile, en limitant l’accès à certaines fonctionnalités et en changeant la manière d’interagir à certains endroits.
        </h4>
        <div class="p-wolcen__fourpart-wolcen6 -grid mt120">
          <div>
            <figure class="-picsShadow">
              <img src="@/assets/cs-wolcen/mobile/home.jpg" srcset="@/assets/cs-wolcen/mobile/home@2x.jpg 2x" alt="Accueil">
              <figcaption class="-center -note mt16">Accueil</figcaption>
            </figure>
            <figure class="-picsShadow">
              <img src="@/assets/cs-wolcen/mobile/builds.jpg" srcset="@/assets/cs-wolcen/mobile/builds@2x.jpg 2x" alt="Liste des builds">
              <figcaption class="-center -note mt16">Liste des builds</figcaption>
            </figure>
            <figure class="-picsShadow">
              <img src="@/assets/cs-wolcen/mobile/builds_pst.jpg" srcset="@/assets/cs-wolcen/mobile/builds_pst@2x.jpg 2x" alt="Arbre de compétence">
              <figcaption class="-center -note mt16">Arbre de compétence</figcaption>
            </figure>
            <figure class="-picsShadow">
              <img src="@/assets/cs-wolcen/mobile/builds_pst:minimized.jpg" srcset="@/assets/cs-wolcen/mobile/builds_pst:minimized@2x.jpg 2x" alt="Arbre de compétence (plein écran)">
              <figcaption class="-center -note mt16">Arbre de compétence (plein écran)</figcaption>
            </figure>
          </div>
          <div>
            <figure class="-picsShadow">
              <img src="@/assets/cs-wolcen/mobile/builds_pst_ttp.jpg" srcset="@/assets/cs-wolcen/mobile/builds_pst_ttp@2x.jpg 2x" alt="Élément sélectionné">
              <figcaption class="-center -note mt16">Élément sélectionné</figcaption>
            </figure>
            <figure class="-picsShadow">
              <img src="@/assets/cs-wolcen/mobile/builds_info.jpg" srcset="@/assets/cs-wolcen/mobile/builds_info@2x.jpg 2x" alt="Description d’un build">
              <figcaption class="-center -note mt16">Description d’un build</figcaption>
            </figure>
            <figure class="-picsShadow">
              <img src="@/assets/cs-wolcen/mobile/menu.jpg" srcset="@/assets/cs-wolcen/mobile/menu@2x.jpg 2x" alt="Menu (ouverture pageslide)">
              <figcaption class="-center -note mt16">Menu (ouverture pageslide)</figcaption>
            </figure>
          </div>
          <div>
            <figure class="-picsShadow">
              <img src="@/assets/cs-wolcen/mobile/database.jpg" srcset="@/assets/cs-wolcen/mobile/database@2x.jpg 2x" alt="Base de données d'équipements">
              <figcaption class="-center -note mt16">Base de données d'équipements</figcaption>
            </figure>
            <figure class="-picsShadow">
              <img src="@/assets/cs-wolcen/mobile/my-profil.jpg" srcset="@/assets/cs-wolcen/mobile/my-profil@2x.jpg 2x" alt="Profil">
              <figcaption class="-center -note mt16">Profil</figcaption>
            </figure>
          </div>
        </div>
      </div>
    </section>
    <section class="p-wolcen__fivepart pt160 pb200">
      <div class="wrap">
        <h2 class="p-wolcen__fivepart-wolcen1" data-aos="fade-up"  data-aos-delay="300">
        Conclusion</h2>
        <div class="p-wolcen__fivepart-wolcen2 -grid mt40" data-aos="fade-up"  data-aos-delay="300">
          <p class="-body">
            Il s’agissait de la première fois où je travaillais sur un produit aussi graphique.
            <br><br>
            Rapidement, des décisions ont dû être prises pour convenir aussi bien aux utilisateurs qu’aux développeurs. Alléger les requêtes serveur du site tout en proposant une expérience alternative aux joueurs étaient un réel défi, très intéressant à relever.
          </p>
          <p class="-body">
            La plateforme a vraiment séduit les joueurs : d’après les analytics de la plateforme, nous avons atteint des plafonds de 10 000 utilisateurs connectés en même temps (pour une moyenne de 40 000 par jour), répartis sur plus d’une vingtaine de pays différents, et mis à disposition un discord qui nous permettait de garder une communication rapide et fluide sur l’état de la plateforme.
          </p>
        </div>
        <div class="p-wolcen__fivepart-wolcen3 -grid mt120" data-aos="fade-up">
          <figure class="-picsShadow">
            <img src="@/assets/cs-wolcen/global/analytics-global.jpg" srcset="@/assets/cs-wolcen/global/analytics-global@2x.jpg 2x" alt="Profil">
          </figure>
          <figure class="-picsShadow">
            <img src="@/assets/cs-wolcen/global/analytics-timeline.jpg" srcset="@/assets/cs-wolcen/global/analytics-timeline@2x.jpg 2x" alt="Profil">
          </figure>
        </div>
        <figure class="p-wolcen__fivepart-wolcen4 -picsShadow mt24" data-aos="fade-up">
          <img src="@/assets/cs-wolcen/global/analytics-location.jpg" srcset="@/assets/cs-wolcen/global/analytics-location@2x.jpg 2x" alt="Profil">
        </figure>
        <div class="p-wolcen__fivepart-wolcen2 -grid mt120" data-aos="fade-up"  data-aos-delay="300">
          <p class="-body">
            Je suis assez fier de ce que j’ai réalisé. Comme toujours, c’était loin d’être parfait mais je n’ai pas le ressenti d’avoir commis de grosses erreurs comme sur d’autres projets, seulement des prises de décision qui menaient à des expériences d’utilisation différentes. 
            <br><br>
            Finalement, le seul vrai point noir de ce projet aura été le jeu en lui-même qui, même s’il était prometteur au départ, s’est retrouvé avec de trop nombreux bugs (même encore maintenant, 2 ans plus tard). 
          </p>
          <p class="-body">
            Devant l’exaspération des joueurs, le jeu s’est retrouvé progressivement avec une mauvaise réputation et une communauté qui a progressivement déserté le monde de Wolcen.
            <br><br>
            Je ne pense pas que le jeu soit réellement perdu (il suffit de voir le rebond de No Man’s Sky après son départ chaotique), il est néanmoins triste d’avoir parié sur un jeu qui a eu un succès trop bref et pas les avis positifs escomptés.
          </p>
        </div>
        <h4 class="p-wolcen__fivepart-wolcen5 mt120" data-aos="fade-up"  data-aos-delay="300">
          Ce fut néanmoins une belle expérience, qui a permis d’enrichir mon expérience sur le design d’interface, car pour une fois il était question autant d’esthétisme que de fonctionnel.
        </h4>
      </div>
    </section>
    <section class="p-nelson__nextprj">
      <ProjectCard
        :project="0"
        :inProject="true"
      />
    </section>
    <TheFooter :darkFooter="false" :darkMode="darkMode"/>
    <ScrollTop />
  </div>
</template>

<script>
import TheFooter from "@/components/TheFooter.vue"
import ScrollTop from '@/components/ScrollTop.vue'
import ProjectCard from '@/components/ProjectCard.vue'
import HeadProject from '@/components/HeadProject.vue'
import Splitting from "splitting"
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";

export default {
  name: 'WolcenUniverse',
  components: {
    ProjectCard,
    ScrollTop,
    HeadProject,
    TheFooter
  },
  props: {
    darkMode: String
  },
  methods: {
    handlePrev(btn) {
      document.activeElement.blur();
      btn.goToPrev();
    },
    handleNext(btn) {
      document.activeElement.blur();
      btn.goToNext();
    }
  },
  mounted () {
    window.scrollTo(0, 0);
    document.title = 'Wolcen Universe — Centis Menant'
    this.$emit('is-lifted');
    Splitting();
  }
}
</script>

<style lang="scss">
  @import '@/assets/var.scss';

  .p-wolcen {
    color: $white;

    &__intro {
      position: relative;
      background: #161111 url('~@/assets/cs-wolcen/bg@2x.jpg') repeat;
      background-size: 50%;
      background-position: 0 0;
      z-index: 1;
      padding-bottom: 16.25rem;

      @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 72dpi) {
        &__intro {
          background: #161111 url('~@/assets/cs-wolcen/bg.jpg') 50% 50% repeat;
        }
      }
      
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0.4;
        background-image: linear-gradient(-45deg, #030202 0%, rgba(0,0,0,0.20) 50%, #000000 100%);
        z-index: -1;
      }

      &-wolcen1 {
        grid-column: 1 / span 12;
      }

      &-wolcen2 {
        grid-column: 1 / span 7;
        font-family: $serif;
      }

      &-wolcen3 {
        grid-column: 1 / span 7;
      }

      &-wolcen4 {
        position:relative;
        z-index: 2;
      }

      &-wolcen5 {
        grid-column: 1 / span 12;
        grid-template-columns: repeat(13, 1fr);

        &-left {
          grid-column: 1 / span 5;
          font-family: $serif;
        }

        &-right {
          grid-column: 7 / span 5;
        }
      }

      &-wolcen6 {
        grid-column: 3 / span 8;
        font-family: $serif;
        text-align: center;
      }
    }

    &__firstpart {
      position: relative;
      background: #161111 url('~@/assets/cs-wolcen/bg@2x.jpg') repeat;
      background-size: 50%;
      background-position: 0 0;
      z-index: 2;

      @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 72dpi) {
        &__intro {
          background: #161111 url('~@/assets/cs-wolcen/bg.jpg') 50% 50% repeat;
        }
      }
      
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0.8;
        background: linear-gradient(-45deg, #030202 0%, #2E0E0E 50%, #000000 100%);
        z-index: -1;
      }

      & .wrap {
        position: relative;
        z-index: 2;
      }

      &-wolcen1 {
        grid-column: 4 / span 6;
        margin-top: -16.25rem;
      }

      &-wolcen2 {
        grid-column: 1 / span 12;
      }

      &-wolcen3 {
        grid-column: 1 / span 7;
        font-weight: bold;
      }

      &-wolcen4 {
        grid-column: 1 / span 5;
        font-family: $serif;
      }

      &-wolcen5 {
        grid-column: 1 / span 12;

        & .-grid {
          & .-body:nth-child(1) {
            grid-column: 1 / span 5;
          }

          & .-body:nth-child(2) {
            grid-column: 7 / span 5;
          }
        }
      }

      &-wolcen6 {
        grid-column: 1 / span 12;
        display: flex;
        margin-left: -0.75rem; 
        margin-right: -0.75rem; 

        & figure {
          margin: 0 0.75rem;
        }
      }

      &-wolcen7 {
        grid-column: 1 / span 7;
        font-family: $serif;
      }

      &-wolcen8 {
        position:relative;
        z-index: 2;
      }

      &-wolcen9 {
        grid-column: 1 / span 12;
        
        & .-h4 {
          grid-column: 1 / span 5;
          font-family: $serif;
        }

        & .-body {
          grid-column: 7 / span 5;
        }
      }

      &-wolcen10 {
        grid-column: 1 / span 12;
        align-items: center;
        
        & img:nth-child(1) {
          grid-column: 1 / span 5;          
        }

        & img:nth-child(2) {
          grid-column: 6 / span 7;          
        }
      }
      
      &-wolcen11 {
        grid-column: 1 / span 12;
        
        & .-body:nth-child(1) {
          grid-column: 1 / span 5;
        }

        & .-body:nth-child(2) {
          grid-column: 7 / span 5;
        }
      }
    }

    &__secondpart {
      position: relative;
      background: #161111 url('~@/assets/cs-wolcen/bg@2x.jpg') repeat;
      background-size: 50%;
      background-position: 0 0;
      z-index: 3;

      @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 72dpi) {
        &__intro {
          background: #161111 url('~@/assets/cs-wolcen/bg.jpg') 50% 50% repeat;
        }
      }
      
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0.8;
        background-image: linear-gradient(-45deg, #070707 0%, #250A0A 52%, #0A0A09 100%);
        z-index: -1;
      }

      &-wolcen1 {
        grid-column: 1 / span 12;
      }

      &-wolcen2 {
        grid-column: 1 / span 7;
        font-weight: bold;
      }

      &-wolcen3 {
        grid-column: 1 / span 12;

        & .-body {
          grid-column: 1 / span 5;
        }

        & .-h4 {
          grid-column: 7 / span 5;
          font-family: $serif;
          color: $white;
          align-self: flex-end;
        }
      }

      &-wolcen4 {
        grid-column: 1 / span 12;

        & img {
          position: relative;
          max-width: 100%;
        }
      }
    }

    &__thirdpart {
      position: relative;
      background: #161111 url('~@/assets/cs-wolcen/bg@2x.jpg') repeat;
      background-size: 50%;
      background-position: 0 0;
      z-index: 4;

      @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 72dpi) {
        &__intro {
          background: #161111 url('~@/assets/cs-wolcen/bg.jpg') 50% 50% repeat;
        }
      }
      
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0.6;
        background-image: linear-gradient(-45deg, #030202 0%, rgba(0,0,0,0.20) 50%, #000000 100%);
        z-index: -1;
      }

      &-wolcen1 {
        grid-column: 1 / span 12;
      }

      &-wolcen2 {
        grid-column: 1 / span 12;
        
        & .-body:nth-child(1) {
          grid-column: 1 / span 5;
        }

        & .-body:nth-child(2) {
          grid-column: 7 / span 5;
        }
      }

      &-wolcen3 {
        grid-column: 1 / span 5;
        font-weight: bold;
      }

      &-wolcen4 {
        grid-column: 1 / span 5;
      }

      &-wolcen5 {
        grid-column: 1 / span 12;
        display: flex;
        margin-left: $px-12;
        margin-right: $px-12;

        & figure {
          margin-left: $px12;
          margin-right: $px12;

          & img {
            position: relative;
            max-width: 100%;
          }
        }
      }

      &-wolcen6 {
        grid-column: 1 / span 12;

        & img {
          position: relative;
          max-width: 100%;
        }
      }

      &-wolcen7 {
        grid-column: 3 / span 8;
        font-family: $serif;
        text-align: center;
      }

      &-wolcen8 {
        grid-column: 1 / span 12;

        & .-body {
          grid-column: 1 / span 5;
        }

        & .-h4 {
          grid-column: 7 / span 5;
          font-family: $serif;
          color: $white;
          align-self: flex-end;
        }
      }

      &-wolcen9 {
        grid-column: 1 / span 5;
        font-family: $serif;
      }

      &-wolcen10 {
        grid-column: 1 / span 12;

        & .-h4 {
          grid-column: 1 / span 5;
          font-family: $serif;
          color: $white;
        }

        & .-body {
          grid-column: 7 / span 5;
        }
      }
    }

    &__fourpart {
      position: relative;
      background: #161111 url('~@/assets/cs-wolcen/bg@2x.jpg') repeat;
      background-size: 50%;
      background-position: 0 0;
      z-index: 5;

      @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 72dpi) {
        &__intro {
          background: #161111 url('~@/assets/cs-wolcen/bg.jpg') 50% 50% repeat;
        }
      }
      
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0.8;
        background-image: linear-gradient(-45deg, #070707 0%, #250A0A 52%, #0A0A09 100%);
        z-index: -1;
      }

      & .wrap {
        position: relative;
        z-index: 2;
      }

      &-wolcen1 {
        grid-column: 1 / span 12;
      }

      &-wolcen2 {
        grid-column: 1 / span 5;
        font-family: $serif;
      }

      &-wolcen3 {
        grid-column: 1 / span 12;

        & .-body:nth-child(1) {
          grid-column: 1 / span 5;
        }

        & .-body:nth-child(2) {
          grid-column: 7 / span 5;
        }
      }

      &-wolcen4 {
        grid-column: 1 / span 12;
        display: flex;
        margin-left: -0.75rem; 
        margin-right: -0.75rem; 

        & figure {
          margin: 0 0.75rem;
        }
      }

      &-wolcen5 {
        grid-column: 3 / span 8;
        font-family: $serif;
        text-align: center;
      }

      &-wolcen6 {
        grid-column: 1 / span 12;
        
        & > div {
          grid-column: span 4;

          & figure {
            margin-bottom: 2.5rem;
          }

          & figure:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &__fivepart {
      position: relative;
      background: #161111 url('~@/assets/cs-wolcen/bg@2x.jpg') repeat;
      background-size: 50%;
      background-position: 0 0;
      z-index: 6;

      @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 72dpi) {
        &__intro {
          background: #161111 url('~@/assets/cs-wolcen/bg.jpg') 50% 50% repeat;
        }
      }
      
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0.6;
        background-image: linear-gradient(-45deg, #030202 0%, rgba(0,0,0,0.20) 50%, #000000 100%);
        z-index: -1;
      }

      &-wolcen1 {
        grid-column: 1 / span 12;
      }

      &-wolcen2 {
        grid-column: 1 / span 12;
        
        & .-body:nth-child(1) {
          grid-column: 1 / span 5;
        }

        & .-body:nth-child(2) {
          grid-column: 7 / span 5;
        }
      }

      &-wolcen3 {
        grid-column: 1 / span 12;
        align-items: flex-end;
        
        & figure:nth-child(1) {
          grid-column: 1 / span 5;
        }

        & figure:nth-child(2) {
          grid-column: 6 / span 7;
        }
      }

      &-wolcen4 {
        grid-column: 1 / span 12;
      }

      &-wolcen5 {
        grid-column: 3 / span 8;
        font-family: $serif;
        color: $white;
        text-align: center;
      }
    }

    & .-separ {
      display: block;
      height: $px1;
      width: 100%;
      grid-column: 1 / span 12;
      background-color: $white5;
    }
    
    & .-body {
      color: $white50;
    }
      
    & figcaption.-note {
      color: $white50;
    }

    & figcaption.-center {
      text-align: center;
    }

    & .-picsShadow img {
      border-radius: 0.5rem;
      box-shadow: 0 0.625rem 3.75rem -1rem rgba(0,0,0,1);
    }
  }

  @media screen and (max-width: $tablet) {
    .p-wolcen {
      &__firstpart {
        &-wolcen6 {
          margin-left: 0; 
          margin-right: 0; 
          flex-direction: column;

          & figure {
            margin: 0;
          }
        }

        &-wolcen10 {
          & img:nth-child(1) {
            grid-column: 1 / span 12;          
          }

          & img:nth-child(2) {
            grid-column: 1 / span 12;          
          }
        }
      }

      & .-grid p {
        margin-top: $px24;
      }

      & .-grid p:first-child {
        margin-top: 0;
      }
    }
  }

  @media screen and (max-width: $phone) {
    .p-wolcen {

      &__thirdpart {
        &-wolcen5 {
          flex-wrap: wrap;

          & figure {
            flex: 1 0 45%;
          }
        }
      }

      &__fourpart {
        &-wolcen4 {
          flex-direction: column;

          & *:first-child {
            margin-bottom: $px24;
          }
        }

        &-wolcen6 {
          & figure {
            margin-bottom: $px40;
          }

          & figure:first-child {
            margin-bottom: 0;
          }
        }
      } 
      
      &__fivepart {
        &-wolcen3 {
          & *:first-child {
            margin-bottom: $px24;
          }
        }
      }
    }
  }

</style>