/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '16-pinterest': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8 0a8 8 0 00-3.21 15.327c-.023-.558-.004-1.23.138-1.837l1.03-4.359s-.256-.51-.256-1.266c0-1.185.688-2.07 1.543-2.07.727 0 1.08.546 1.08 1.2 0 .732-.468 1.826-.707 2.84-.2.849.425 1.54 1.262 1.54 1.516 0 2.536-1.946 2.536-4.253 0-1.753-1.18-3.065-3.328-3.065-2.426 0-3.938 1.809-3.938 3.83 0 .698.205 1.189.527 1.569.148.176.168.245.114.447l-.162.641c-.054.203-.218.276-.4.2-1.119-.456-1.639-1.68-1.639-3.056 0-2.272 1.916-4.998 5.717-4.998 3.055 0 5.065 2.212 5.065 4.583 0 3.139-1.744 5.483-4.317 5.483-.863 0-1.675-.466-1.954-.996 0 0-.465 1.843-.563 2.198-.169.617-.501 1.234-.805 1.714A8 8 0 0016 8 8 8 0 008 0" _fill="#000" fill-rule="evenodd"/>'
  }
})
