<template>
  <section class="head-project">
    <div class="wrap">
      <h1 :title="titleFir + titleSec" data-splitting class="head-project-title mt80 mb80">
        <span>{{ titleFir }}</span>
        <span :class="backline ? '-backline' : ''">{{ titleSec}}</span>
      </h1>
      <ul class="head-project-tags">
        <li class="head-project-tags__tag">
          <span data-splitting class="head-project-tags__tag-label -spaced">Type</span>
          <span data-splitting class="head-project-tags__tag-value -note">{{ type }}</span>
        </li>
        <li class="head-project-tags__tag">
          <span data-splitting class="head-project-tags__tag-label -spaced">Client</span>
          <span data-splitting class="head-project-tags__tag-value -note">{{ client }}</span>
        </li>
        <li class="head-project-tags__tag">
          <span data-splitting class="head-project-tags__tag-label -spaced">Rôle</span>
          <span data-splitting class="head-project-tags__tag-value -note">{{ role }}</span>
        </li>
        <li class="head-project-tags__tag">
          <span data-splitting class="head-project-tags__tag-label -spaced">Date</span>
          <span data-splitting class="head-project-tags__tag-value -note">{{ date }}</span>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
  import Splitting from "splitting"
  import "splitting/dist/splitting.css";
  import "splitting/dist/splitting-cells.css";

  export default {
    name: 'ScrollTop',
    props: {
      titleFir: String,
      titleSec: String,
      backline: Boolean,
      type: String,
      client: String,
      role: String,
      date: String
    },
    methods: {
      ScrollTo() {
        window.scrollTo({top: 0, behavior: 'smooth'});
      }
    },
    mounted() {
      Splitting();
    }
  }
</script>

<style lang="scss">
  @import '@/assets/var.scss';

  .head-project {
    position: relative;
    text-align: center;
    z-index: 1;

    &-title {
      grid-column: 2 / span 10;
      grid-row-start: 1;

      & > span {
        transition: color 0.3s;
        color: $grey-600;

        &:first-child {
          color: $grey-900;
        }
      }

      & .-backline {
        display: block;
      }

      &.splitting .char,
      &.splitting .char {
        position: relative;
        animation: projectSlideIn 1s cubic-bezier(.5, 0, .5, 1) both;
        animation-delay: calc(16ms * var(--char-index));
        margin: 0.125rem 0;
        transform: translateY(2rem);
        opacity: 0;
      }
    }

    @keyframes projectSlideIn {
      to {
        transform: translateY(0);
        opacity: 1;
      }
    }

    @keyframes fadeIn {
      to {
        opacity: 1;
      }
    }

    &-tags {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      column-gap: $px24;
      grid-column: 1 / span 12;
      grid-row-start: 2;
      margin-top:$px40;
      margin-bottom:$px24;
      
      &__tag {
        display: grid;
        grid-column: span 3;

        &-label {
          grid-row-start: 1;
          font-size: $px12;
          font-weight: 600;
          color: $grey-900;
          margin-bottom: $px4;
          transition: color 0.3s;
        }

        &-value {
          grid-row-start: 2;
          color: $grey-600;
          transition: color 0.3s;
        }

        & .splitting .char,
        & .splitting .char {
          position: relative;
          animation: fadeIn 1s cubic-bezier(.5, 0, .5, 1) both;
          animation-delay: calc(20ms * var(--char-index) + 500ms);
          opacity: 0;
        }
      }
    }
  }

  @media screen and (max-width: $phone) {
    .wrap.head-project  {
      padding: 0;
      margin: 0;
    }

    .wrap {
      margin: 0;
      padding: 0;
    }
    
    .head-project {
      &-title.mb80 {
        width: 100%;
        margin-bottom: $px40;
      }

      &-tags {
        &__tag {
          grid-column: span 6;
          margin-top: 2rem;
        }
      }
    }
  }


  .darkmode {
    .head-project {

      &-title {

        & > span {
          color: $white50;

          &:first-child {
            color: $white;
          }
        }
      }

      &-tags {
        &__tag {

          &-label {
            color: $white;
          }

          &-value {
            color: $white50;
          }
        }
      }
    }
  }
</style>