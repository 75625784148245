<template>
  <div class="about">
    <section class="about__intro wrap">
      <h1
        title="Je suis passionné par les métiers créatifs depuis tout petit, tout support confondu.
        Inspirer et être inspirer, communiquer grâce à un langage universel, faciliter la compréhension et l’utilisation de concepts complexes…
        Des exemples qui me font apprécier mon métier."
        data-splitting 
        class="about__intro-about1 -h2
      ">
        Je suis passionné par les métiers créatifs depuis tout petit, tout support confondu.<br />
        Inspirer et être inspiré, communiquer grâce à un langage universel, faciliter la compréhension et l’utilisation de concepts complexes…<br />
        Des exemples qui me font apprécier mon métier.
      </h1>
      <div class="about__intro-about2 -grid mt160">
        <div class="about__intro-about2__content -body">
          <h2 class="about__intro-about2__title -h3 mt80" data-aos="fade-up" data-aos-offset="0">
            Premiers souvenirs
          </h2>
          <p class="-body mt40" data-aos="fade-up" data-aos-offset="0">D'aussi loin que je me souvienne, j'ai toujours aimé créer des choses. Ce sentiment d'extraire progressivement une partie de soi, c'est comme assister à une naissance : on se sent à la fois <strong>fier</strong>, <strong>effrayé</strong>, puis <strong>grandi</strong>. C'est également un fragment de nous qui sera figé dans le temps, lorsqu’il aura reçu sa dernière retouche ; c'est une trace de ce qu'on a été à un instant, et qui fera partie de cet ensemble de choses qui nous ont construit tout au long de notre vie.</p><br />
          <p class="-body" data-aos="fade-up">Mes premiers souvenirs de dessin viennent de mes 8 ans je crois, avec une feuille de papier, un crayon et une main comme modèle. <strong>J'ai toujours adoré dessiner des mains</strong>. C'est l’un des plus beaux outils que la nature nous ait offert, et l'un des plus complexes à représenter. Puis au fur et à mesure du temps, j’ai testé un peu tout et n’importe quoi : <i>peinture à l’eau</i>, <i>pastels</i>, <i>fusain</i>, <i>lavis</i>, <i>argile</i>, <i>bois</i>, etc. Qu’importe le matériel, j'ai toujours trouvé enivrant le fait de concevoir. Comme si quelque chose en moi essayait expressément de communiquer et son seul moyen d’y parvenir était la création.</p><br />
          <p class="-body" data-aos="fade-up">À 16 ans, je découvre pour la première fois <i>Photoshop</i> et de fil en aiguille, de créations en créations, le métier de <i>graphiste</i>, puis de <i>UI designer</i>. <strong>Et c'est ainsi que tout a commencé</strong>.</p>
        </div>
      </div>
    </section>
    <section class="about__firstpart pt200 pb160">
      <div class="about__firstpart-about1" data-aos="fade-right" />
      <div class="wrap">
        <div class="about__firstpart-about2" data-aos="fade-in">
          <h2 class="-legend">
            <span>Un univers riche </span>
            <span>de possibilités</span>
          </h2>
        </div>
        <p class="about__firstpart-about3 -body mt80" data-aos="fade-up">
          J’ai commencé à avoir mes premiers clients à 19 ans, avant même d’aller dans une école pour en étudier le sujet. L’expérience du métier et du monde professionnel a été assez rapidement assimilée, ce qui a été un boost incroyable lorsque je suis revenu à l’école, après trois années en autodidacte. Quel plaisir de se former soi-même, puis de comparer sa progression une fois de retour à l’école !
        </p>
        <div class="about__firstpart-about4 -grid mt160">
          <div class="about__firstpart-about5" data-aos="fade-right">
            <img src="@/assets/about/olddraw.jpg" srcset="@/assets/about/2x/olddraw.jpg 2x" alt="Vieux dessin">
          </div>
          <div class="about__firstpart-about6 mt80" data-aos="fade-left">
            <p class="-body">Auparavant, j’avais étudié de mon propre chef <i>la psychologie, la neurologie, les sciences du langage, l’histoire de l’art, le marketing, la comptabilité,</i> et beaucoup d’autres matières diverses et variées. Un ensemble de thématiques et de concepts que j’ai pu réutiliser après, aux moments les plus opportuns, <strong>le plus souvent lors de projets qui en requéraient l'usage</strong>.</p><br />
            <p class="-body">J’ai toujours aimé fonctionner ainsi : <strong>maîtriser les bases d’un domaine d’expertise, sans chercher à en devenir un expert.</strong> Après tout, la <i>créativité</i> et <i>l’inventivité</i> viennent de notre capacité à sortir pendant un instant de la thématique de réflexion, de s’affranchir des limites et contraintes inhérentes qu’elle occasionne, afin d’y trouver de nouvelles idées (parfois provenant d'univers qui n’ont rien à voir 🤷). </p><br />
            <p class="-body">Jetez un oeil à l’histoire de la création de la <i>Nike Air Max</i>, directement inspiré du <i>Centre Pompidou</i> à Paris. <strong>Un très bel exemple selon moi.</strong></p>
          </div>
        </div>
        <div class="about__firstpart-about7 -grid mt120">
          <p class="about__firstpart-about8 -h1" data-aos="fade-right">
            Créer. Aimer. Rêver. Puis recommencer.
          </p>

          <div class="about__firstpart-about9" data-aos="fade-left">
            <img src="@/assets/about/centis.jpg" srcset="@/assets/about/2x/centis.jpg 2x" alt="Photo de Centis">
          </div>
        </div>
        <h2 class="about__firstpart-about10 -h3 mt160" data-aos="fade-up">
          Et maintenant ?
        </h2>
        <div class="about__firstpart-about11 -body mt40" data-aos="fade-up">
          <p class="-body">
            Je continue toujours à avancer dans ce monde plein de surprises. J’ai déjà créé plusieurs entreprises, participé à la conception de nombreux outils numériques différents, chacun avec leurs propres thématiques / besoins / cibles respectives, rencontré des personnes extraordinaires et donné des cours à des personnes qui l’étaient tout autant. Et ce n'est qu'un début.
          </p><br />
          <p class="-body">
            Je regarde l’avenir d'un oeil avenant : mes expériences m'ont fait vivre des sensations incroyables et je vise à continuer sur cette voie. On vit dans un monde magnifique, et ce que je vois devant moi maintenant, ce n’est rien d’autre qu’<strong>un univers riche de possibilités</strong>.
          </p>
        </div>
        <figure class="about__firstpart-about12 mt160" data-aos="fade-up">
          <img src="@/assets/about/desktop.jpg" srcset="@/assets/about/2x/desktop.jpg 2x" alt="Photo de mon bureau" />
          <figcaption class="about__firstpart-about12 mt16">
            Rare photo d'archive d'un bureau bien rangé.
          </figcaption>
        </figure>
      </div>
    </section>
    <section class="about__secondpart pt160" data-aos="fade-up">
      <div class="wrap">
        <span class="about__secondpart-about1 -spaced" data-aos="fade-up">Compétences</span>
        <h2 class="about__secondpart-about2 -legend" data-aos="fade-up">XP et outils.</h2>
        <p class="about__secondpart-about3 -body mt40" data-aos="fade-up">
          <strong>Je ne suis expert en rien car curieux de tout.</strong><br />
          J’ai toujours préféré garder un oeil sur tout, ce qui en résulte l'impossibilité d'être expert dans un domaine précis (il fallait faire un choix, mon cerveau ayant ses limites). <br /><br />
          Ainsi, j'évite d’approfondir au maximum un domaine, car j’en perds la naïveté nécessaire à toute bonne créativité. Cependant, je construis sur chaque projet une structure de connaissances solides, puis me laisse porter par le sujet, flânant, orienté par la direction que prennent mes recherches.
        </p>
        <h3 class="about__secondpart-about4 mt160" data-aos="fade-up">
          Compétences
        </h3>
        <ul class="about__secondpart-about5 -grid mt40" data-aos="fade-up">
          <li>Design systems.</li>
          <li>UI Design.</li>
          <li>Print.</li>
          <li>Motion.</li>
          <li>Prototyping.</li>
          <li>Visual identity.</li>
          <li>Product strategy.</li>
          <li>Illustrations.</li>
        </ul>
        <h3 class="about__secondpart-about6 mt160" data-aos="fade-up">
          Logithèque
        </h3>
        <ul class="about__secondpart-about7 -grid mt40">
          <li>
            <ul class="about__secondpart-about7__part" data-aos="fade-up">
              <li class="about__secondpart-about7__part-label -spaced mb8">Produit</li>
              <li class="about__secondpart-about7__part-value">Sketch</li>
              <li class="about__secondpart-about7__part-value">Figma</li>
              <li class="about__secondpart-about7__part-value">Zeplin</li>
              <li class="about__secondpart-about7__part-value">Invision</li>
              <li class="about__secondpart-about7__part-value">Miro</li>
              <li class="about__secondpart-about7__part-value">Visual Studio Code</li>
              <li class="about__secondpart-about7__part-value">Principle</li>
              <li class="about__secondpart-about7__part-value">Framer</li>
            </ul>
          </li>
          <li>
            <ul class="about__secondpart-about7__part" data-aos="fade-up" data-aos-delay="100">
              <li class="about__secondpart-about7__part-label -spaced mb8">Identité</li>
              <li class="about__secondpart-about7__part-value">Adobe Photoshop</li>
              <li class="about__secondpart-about7__part-value">Adobe Camera Raw</li>
              <li class="about__secondpart-about7__part-value">Adobe Illustrator</li>
              <li class="about__secondpart-about7__part-value">Affinity Design</li>
              <li class="about__secondpart-about7__part-value">Procreate</li>
            </ul>
          </li>
          <li>
            <ul class="about__secondpart-about7__part" data-aos="fade-up" data-aos-delay="200">
              <li class="about__secondpart-about7__part-label -spaced mb8">Autres</li>
              <li class="about__secondpart-about7__part-value">Notion</li>
              <li class="about__secondpart-about7__part-value">Trello</li>
              <li class="about__secondpart-about7__part-value">Asana</li>
              <li class="about__secondpart-about7__part-value">Jira</li>
              <li class="about__secondpart-about7__part-value">Monday</li>
              <li class="about__secondpart-about7__part-value">Spotify 😅</li>
            </ul>
          </li>
        </ul>
        <h3 class="about__secondpart-about8 mt160" data-aos="fade-up">
          Pédagogie
        </h3>
        <div class="about__secondpart-about9 -grid mt40 mb100">
          <p class="about__secondpart-about9__leftcontent -body" data-aos="fade-up">
            Je suis également intervenant dans des écoles de niveau post-bac (<a class="-lightlink" href="https://www.hetic.net/" target="_blank">HETIC</a>, <a class="-lightlink" href="https://www.esin-ecole.com/" target="_blank">ESIN</a>). Les matières enseignées sont principalement orientées vers le design graphique et le design produit. <br /><br />
            J'oriente ma pédagogie vers un côté principalement pratique, à travers la maîtrise de logiciels tels que <i>Photoshop, Illustrator, Sketch ou Figma</i>.
          </p>
          <p class="about__secondpart-about9__rightcontent -body mt48" data-aos="fade-up">
            Néanmoins, une base théorique restant nécessaire, je m'emploie à l'expliquer au travers de <strong>concepts concrets et utiles</strong> pour la pratique du design, mais également à l'aide de <strong>sources d'inspirations</strong> et de <strong>ma propre expérience personnelle.</strong><br /><br />
            L'objectif de mes cours est de faire en sorte que l'utilisation de ces outils (pratiques et théoriques) ne soit en aucun cas un frein pour créer à partir d'une idée, d'un concept.
          </p>
          <ul class="about__secondpart-about9__list">
            <li class="about__secondpart-about9__list--label" data-aos="fade-up">
              Quelques sujets abordés en cours :
            </li>
            <li class="about__secondpart-about9__list--elem" data-aos="fade-up">
              Théorie des couleurs
            </li>
            <li class="about__secondpart-about9__list--elem" data-aos="fade-up">
              Extensions d'images
            </li>
            <li class="about__secondpart-about9__list--elem" data-aos="fade-up">
              Techniques de détourage sur Photoshop
            </li>
            <li class="about__secondpart-about9__list--elem" data-aos="fade-up">
              Retouche non-destructive d'images
            </li>
            <li class="about__secondpart-about9__list--elem" data-aos="fade-up">
              <a class="-lightlink" href="https://www.youtube.com/watch?v=iCrargw1rrM" target="_blank">
                Step by step
              </a>
              (low, medium, high fidelity)
            </li>
            <li class="about__secondpart-about9__list--elem" data-aos="fade-up">
              Les bases de la composition (flow, rythme, dominance, point de focal, etc)
            </li>
            <li class="about__secondpart-about9__list--elem" data-aos="fade-up">
              Gestalt Theory
            </li>
            <li class="about__secondpart-about9__list--elem" data-aos="fade-up">
              Comprendre la créativité
            </li>
            <li class="about__secondpart-about9__list--elem" data-aos="fade-up">
              Les fondamentaux du design d'interface
            </li>
            <li class="about__secondpart-about9__list--elem" data-aos="fade-up">
              Grilles
            </li>
            <li class="about__secondpart-about9__list--elem" data-aos="fade-up">
              Typographie
            </li>
            <li class="about__secondpart-about9__list--elem" data-aos="fade-up">
              Pixel perfect precision
            </li>
            <li class="about__secondpart-about9__list--elem" data-aos="fade-up">
              Design mobile et responsive
            </li>
          </ul>
        </div>
      </div>
      <div class="about__secondpart-about10 -grid mt200 mb200">
        <h3 class="about__secondpart-about10__title -legend" data-aos="fade-up">Envie de discuter ?</h3>
        <p class="about__secondpart-about10__content -body mt24" data-aos="fade-up">
          Je reste à votre écoute, si vous avez des questions, des propositions ou si vous voulez simplement faire connaissance. <br />
          Un bonjour est toujours un vrai plaisir à recevoir, n'hésitez pas !
        </p>
        <ul class="about__secondpart-about10__social mt40">
          <li data-aos="fade-up">
            <a target="_blank" href="mailto:9centis@gmail.com">
              <svgicon name="16-mail" />
            </a>
          </li>
          <li data-aos="fade-up" data-aos-delay="50">
            <a target="_blank" href="https://dribbble.com/centismenant">
            <svgicon name="16-dbbb" />
            </a>
          </li>
          <li data-aos="fade-up" data-aos-delay="100">
            <a target="_blank" href="https://www.instagram.com/fahren9/">
            <svgicon name="16-insta" />
            </a>
          </li>
          <li data-aos="fade-up" data-aos-delay="150">
            <a target="_blank" href="https://linkedin.com/in/centismenant">
            <svgicon name="16-lkd" />
            </a>
          </li>
          <li data-aos="fade-up" data-aos-delay="200">
            <a target="_blank" href="https://www.behance.net/centismenant">
            <svgicon name="16-behance" />
            </a>
          </li>
        </ul>
      </div>
      <div class="about__secondpart-about11 -grid mt80">
        <p data-splitting class="about__secondpart-about11-content -h2" data-aos="fade-in" data-aos-duration="50" data-aos-id="seeyou">See you, <span class="-seeyou">space</span> cowboy.</p>
        <figure class="about__secondpart-about11-illustrations -grid">
          <img class="about__secondpart-about11-illustrations-ship" src="../assets/about/ship.svg" alt="Vaisseau" />
          <img class="about__secondpart-about11-illustrations-space" src="../assets/about/space.svg" alt="Espace, planète et vaisseau décollant" data-aos="fade-up-left" data-aos-delay="400" data-aos-duration="2000"/>
        </figure>
      </div>
    </section>
    <TheFooter :darkFooter="true" :darkMode="darkMode" />
    <ScrollTop />
  </div>
</template>

<script>
import TheFooter from '@/components/TheFooter.vue'
import ScrollTop from '@/components/ScrollTop.vue'
import Splitting from "splitting";
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";

export default {
  name: 'About',
  components: {
    ScrollTop,
    TheFooter
  },
  props: {
    darkMode: String
  },
  mounted () {
    document.title = 'À propos — Centis Menant'
    Splitting();
    
    window.scrollTo(0, 0);
    this.$emit('is-lifted');
    this.$emit('is-dark');

    document.addEventListener('aos:in:seeyou', ({ detail }) => {
      detail.classList.add('-active');
    });
  }
}
</script>

<style lang="scss">
  @import '../assets/var.scss';

  .about {

    & .main {
      position: relative;
      background-color: $white;
      box-shadow: 0 $px16 $px120 0 $black50;
      padding-top: $px160;
      width: auto;
      z-index: 10;
    }

    &__intro {


      &-about1 {
        grid-column: 2 / span 10;
        margin-top: 12.5rem;

        & .word,
        & .word {
          position: relative;
          display: inline-block;
          animation: wordSlideIn 1s cubic-bezier(.5, 0, .5, 1) both;
          animation-delay: calc(16ms * var(--word-index));
        }

        & .char,
        & .char {
          position: relative;
          margin: 0.125rem 0;
          z-index: 2;
        }

        @keyframes wordSlideIn {
          0% {
            transform: translateY(2rem);
            opacity: 0;
          }
          50% {
            transform: translateY(-0.2rem);
            opacity: 1;
          }
        }
      }

      &-about2 {
        grid-column: 1 / span 12;

        /* &__aside {
          grid-column: 1 / span 3;
          margin: $px-16 0;

          &__elem {
            font-size: $px18;
            line-height: $px30;
            box-shadow: inset 0 $px-1 0 0 $grey-200;

            &:last-child {
              box-shadow: inset 0 0 0 0 $grey-200;
            }
            
            &-label {
              font-weight: 600;
              padding-top: $px16;
            }

            &-value {
              padding-bottom: $px16;
            }
          }
        } */

        &__content {
          grid-column: 3 / span 6;
        }

        &__title.-h3 {
          grid-column: 5 / span 6;
          font-weight: 600;
          color: $grey-900;
          font-family: $lineal;
        }
      }
    }

    &__firstpart {
      position: relative;

      &-about1 {
        display: flex;
        justify-content: center;
        margin-right: 15vw;
        height: 30rem;
        overflow: hidden;
        background: $black url('../assets/about/conceptdesign.jpg') repeat;
        background-size: cover;
        background-position: 0 0;
        animation: slide 50s linear infinite;
      }

      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 144dpi) {
        &-about1 {
          background: $black url('../assets/about/2x/conceptdesign.jpg') repeat;
          background-size: cover;
        }
      }

      @keyframes slide {
        from {background-position: 0 0;}
        to {background-position: 0 -150rem;}
      }

      &-about2 {
        grid-column: 3 / span 8;
        margin-top: -5.75rem;
        color: $grey-900;

        & span:first-child {
          color: $white;
          text-shadow: 0 $px2 $px16 $black20;
          display: block;
        }
      }

      &-about3 {
        grid-column: 3 / span 8;
      }

      &-about4 {
        grid-column: 1 / span 12;
      }

      &-about5 {
        grid-column: 1 / span 6;
        
        & img {
          width: 100%;
        }
      }

      &-about6 {
        grid-column: 8 / span 5;
      }

      &-about7 {
        grid-column: 1 / span 12;
        align-items: center;
      }

      &-about8 {
        grid-column: 1 / span 5;
        font-style: italic;
      }

      &-about9 {
        grid-column: 7 / span 6;
        
        & img {
          width: 100%;
          height: auto;
        }
      }

      &-about10.-h3 {
        grid-column: 3 / span 8;
        font-weight: 600;
        font-family: $lineal;
        color: $grey-900;
      }

      &-about11 {
        grid-column: 3 / span 8;
      }

      &-about12 {
        grid-column: 1 / span 12;
        
        & img {
          width: 100%;
        }

        & figcaption {
          @extend .-note;
          grid-column: 2 / span 10;
          text-align: center;
        }
      }
    }

    &__secondpart {
      background-color: $grey-900;
      color: $white;

      &-about1.-spaced {
          grid-column: 1 / span 6;
          color: $white50;
      }

      &-about2 {
        grid-column: 1 / span 6;
      }

      &-about3.-body {
        grid-column: 1 / span 6;
        color: $white50;
      }

      &-about4, &-about6, &-about8 {
        grid-column: 1 / span 12;
        font-family: $lineal;
        font-weight: 600;
      }

      &-about5 {
        grid-column: 1 / span 12;
        margin: -$px12 0;

        & li {
          grid-column: span 3;
          font-family: $serif;
          font-size: $px32;
          line-height: $px48;
          padding: $px16 0;
          margin: $px12 0;
          font-weight: 300;
          box-shadow: inset 0 $px-1 0 $white5;
          word-break: break-word;
        }
      }

      &-about7 {
        grid-column: 1 / span 12;

        & > li {
          grid-column: span 4;
        }

        &__part {
          
          &-label.-spaced {
            opacity: 0.5;
          }

          &-value {
            font-family: $lineal;
            font-size: $px18;
            font-weight: 500;
            line-height: $px30;
          }
        }
      }

      &-about9 {
        grid-column: 1 / span 12;
        
        &__leftcontent.-body {
          grid-column: 1 / span 5;
          color: $white50;
        }
        
        &__rightcontent.-body {
          grid-column: 7 / span 5;
          color: $white50;
        }

        &__list {
          grid-column: 1 / span 12;

          &--label {
            font-size: $px24;
            line-height: $px36;
            font-weight: 600;
            padding: $px16 0;
            margin: $px64 0 $px24;
            word-break: break-all;
          }

          &--elem {
            font-family: $serif;
            font-size: $px24;
            line-height: $px36;
            padding: $px16 0;
            margin: $px4 0;
            font-weight: 300;
            box-shadow: inset 0 $px-1 0 $white5;
            word-break: break-all;
          }
        }
      }

      &-about10 {
        grid-column: 1 / span 12;
        text-align: center;

        &__title.-legend {
          grid-column: 2 / span 10;
          font-family: $serif;
          font-weight: 300;
        }

        &__content.-body {
          grid-column: 2 / span 10;
          color: $white50;
        }

        &__social {
          grid-column: 2 / span 10;
          display: flex;
          justify-content: center;
          margin: $px-4;

          & li {
            margin: $px4;

            & a {
              display: flex;
              padding: $px16;
              background-color: transparent;
              border-radius: 4px;
              transition: background-color 0.3s;

              &:hover,
              &:focus,
              &:active {
                background-color: $white5;
              }

              & svg {
                height: $px16;
                width: $px16;
                fill: $white50;
                transition: fill 0.3s, transform 0.3s;
              }

              &:hover svg,
              &:focus svg,
              &:active svg {
                transform: scale(1.2);
                fill: $white;
              }
            }
          }
        }
      }
    
      &-about11 {
        position: relative;
        grid-column: 1 / span 12;
        overflow: hidden;
        
        &-content {
          position: relative;
          align-self: center;
          justify-self: center;
          grid-column: 1 / span 12;
          grid-row-start: 1;
          color: $white;
          z-index: 2;

          &.-active .-seeyou {
            position: relative;

            &::before {
              content:'';
              position: absolute;
              top: 1.75rem;
              left: -0.375rem;
              right: -0.375rem;
              height: 0.125rem;
              transform: rotate(3deg);
              background-color: $white;
              opacity: 0;
              animation: spaceScratched 2s 2s both;
            }

            &::after {
              content:'<Web>';
              position: absolute;
              top: -2rem;
              left: $px24;
              font-size: $px32;
              font-weight: 600;
              word-break: keep-all;
              transform: rotate(-6deg);
              font-style: italic;
              color: $white20;
              animation: webAppear 2s 2s both;
              opacity: 0;
              transform: translateY(1rem) rotate(-10deg);
            }
          }

          &.splitting.-active .char {
            position: relative;
            animation: seeyouAppear 2s cubic-bezier(.5, 0, .5, 1) both;
            animation-delay: calc(100ms * var(--char-index));
            margin: 0.125rem 0;
            opacity: 0;
          }

          @keyframes spaceScratched {
            to {
              opacity: 1;
            }
          }

          @keyframes webAppear {
            to {
              opacity: 1;
              transform: translateY(0) rotate(-10deg);
            }
          }

          @keyframes seeyouAppear {
            to {
              opacity: 1;
            }
          }
        }

        &-illustrations {
          position: relative;
          align-items: flex-end;
          justify-content: flex-end;
          grid-column: 1 / span 12;
          box-shadow: inset 0 -0.125rem 0 0 $white;
          grid-row-start: 1;
          z-index: 3;

          &-ship {
            grid-column: 1 / span 12;
            grid-row-start: 1;
            z-index: 2;
            overflow: hidden;
          }

          &-space {
            align-self: flex-end;
            justify-self: flex-end;
            margin-bottom: $px2;
            grid-column: 1 / span 12;
            grid-row-start: 1;
            z-index: 1;
            overflow: hidden;
            max-width: 75vw;
          }
        }
      }
    }
  }

  @media screen and (max-width: $tablet) {
    .about {
        &__intro {

        &-about1 {
          grid-column: 2 / span 10;
          margin-top: 12.5rem;
        }

        &-about2 {
          grid-column: 1 / span 12;

          &__content {
            grid-column: 2 / span 10;
          }

          &__title.-h3 {
            grid-column: 2 / span 10;
          }
        }
      }

      &__firstpart {
        position: relative;

        &-about1 {
          margin-right: 0;
        }

        &-about2 {
          grid-column: 2 / span 10;
          margin-top: 5rem;
          color: $grey-900;

          & span:first-child {
            color: $grey-900;
            text-shadow: none;
            display: inline;
          }
        }

        &-about3 {
          grid-column: 2 / span 10;
        }

        &-about4 {
          grid-column: 1 / span 12;
        }

        &-about5 {
          grid-column: 2 / span 10;
        }

        &-about6 {
          grid-column: 2 / span 10;
        }

        &-about7 {
          grid-column: 2 / span 10;
        }

        &-about8 {
          grid-column: 1 / span 12;
          margin-bottom: 7.5rem;
        }

        &-about9 {
          grid-column: 1 / span 12;
        }

        &-about10.-h3 {
          grid-column: 2 / span 10;
        }

        &-about11 {
          grid-column: 2 / span 10;
        }

        &-about12 {
          grid-column: 1 / span 12;
        }
      }

      &__secondpart {

        &-about1.-spaced {
          grid-column: 2 / span 10;
          color: $white50;
        }

        &-about2 {
          grid-column: 2 / span 10;
        }

        &-about3.-body {
          grid-column: 2 / span 10;
          max-width: 40rem;
        }

        &-about4, &-about6, &-about8 {
          grid-column: 2 / span 10;
        }

        &-about5 {
          grid-column: 2 / span 10;

          & > li {
            word-break: break-word;
          }
        }

        &-about7 {
          grid-column: 2 / span 10;
        }

        &-about9 {
          grid-column: 2 / span 10;
          max-width: 40rem;
          
          &__leftcontent.-body {
            grid-column: 1 / span 12;
          }
          
          &__rightcontent.-body {
            grid-column: 1 / span 12;
          }
        }

        &-about10 {
          grid-column: 2 / span 10;

          &__title.-legend {
            grid-column: 2 / span 10;
          }

          &__content.-body {
            grid-column: 2 / span 10;
          }

          &__social {
            grid-column: 2 / span 10;
          }
        }
      
        &-about11 {
          grid-column: 1 / span 12;
        }
      }
    }
  } 

  @media screen and (max-width: $phone) {
    .about {
      overflow: hidden;

      &__intro {
        &-about1 {
          margin-top: 6rem;
        }
      }

      &__firstpart {
        &-about2 {
          & .-legend {
            font-size: 3.5rem;
            line-height: 4.5rem;
          }
        }
      }

      &__secondpart {
        &-about1.-spaced {
          margin-top: 4rem;
        }

        &-about5.mt40 {
          margin-top: 1.5rem;

          & li {
            padding: 0.75rem 0;
            margin: 0.25rem 0;
          }
        }

        &-about7 {
          margin: -1rem; 

          & > li {
            margin: 1rem; 
          }
        }

        &-about9 {
          
          &__list {
            &--elem {
              padding: 0.75rem 0;
              margin: 0.25rem 0;
              word-break: normal;
            }
          }
        }

        &-about10 {
          grid-column: 2 / span 10;

          &__title.-legend {
            margin: 0 1.5rem;
            font-size: 3.5rem;
            line-height: 4.5rem;
            }

          &__content.-body {
            margin: 1.5rem 1.5rem 0;
          }

          &__social.mt40 {
            margin-top: 1.5rem;
          }
        }
      
        &-about11 {
          &-content {
            margin-top: 1.5rem;
            text-align: center;
          }

          &-illustrations {
            &-space {
              position: absolute;
            }
          }
        }
      }
    }
  } 

  .darkmode {
    & .about {

      &__intro {
        &-about1 {
          color: $white;
        }

        &-about2 {

          &__content {
            & p {
              color: $white50;
            }
          }

          &__title.-h3 {
            color: $white;
          }
        }
      }

      &__firstpart {
        
        &-about2 {
          color: $white;

          & span:first-child {
            color: $white;
          }
        }

        &-about3 {
          color: $white50;
        }

        &-about6 {
          & p {
            color: $white50;
          }
        }

        &-about8 {
          color: $white;
        }

        &-about10.-h3 {
          color: $white;
        }

        &-about11 {
          & p {
            color: $white50;
          }
        }

        &-about12 {

          & figcaption {
            color: $white50;
          }
        }
      }
    }

    @media screen and (max-width: $tablet) {
      .about {
        &__firstpart {
          &-about2 {
            color: $white;

            & span:first-child {
              color: $white;
            }
          }
        }

        &__secondpart {
          &-about1.-spaced {
            color: $white50;
          }
        }
      }
    } 
  }
</style>